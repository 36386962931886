import React, { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ICommentItem } from "../types";
import { useTranslation } from "react-i18next";
import { isBefore } from "date-fns";
import { useDispatch } from "react-redux";
import {
  addCommentRequested as addNoteComment,
  deleteCommentRequested as deleteNoteComment,
  updateCommentRequested as updateNoteComment,
} from "../redux/activities/notes/actions";
import {
  addCommentRequested as addObjectiveComment,
  deleteCommentRequested as deleteObjectiveComment,
  updateCommentRequested as updateObjectiveComment,
} from "../redux/activities/objectives/actions";
import ConfirmModal from "./modals/ConfirmModal";
import ItemComment from "./ItemComment";
import { getISOString } from "../helpers/utils";
import { FlexBox, SecondaryText } from "../styles";

type SubmitAction = "add" | "edit" | "delete";

function compare(a: ICommentItem, b: ICommentItem) {
  const dateA = new Date(a.lastUpdate);
  const dateB = new Date(b.lastUpdate);

  if (isBefore(dateA, dateB)) return 1;
  if (isBefore(dateB, dateA)) return -1;
  return 0;
}

interface Props {
  item: "note" | "objective";
  itemId: number;
  data: ICommentItem[];
  loading?: boolean;
}

/**
 * Obtiene las comentarios de una nota.
 * Cada comentario esta ligado a un @username y solo puede ser modificado por el mismo.
 * Operaciones: Alta/Baja/Modificación
 */

const CommentList = ({ item, itemId, data, loading }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [comments, setComments] = useState<ICommentItem[]>([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [toDelete, setToDelete] = useState<Partial<ICommentItem>>();

  React.useEffect(() => {
    const newState: ICommentItem[] = data?.map((e) => ({ ...e }));
    setComments(newState.sort(compare) || []);
  }, [data]);

  const handleSubmit = (action: SubmitAction, data?: Partial<ICommentItem>) => {
    switch (action) {
      case "add":
        if (data && item === "note")
          dispatch(addNoteComment({ ...data, itemId, lastUpdate: getISOString() }));

        if (data && item === "objective")
          dispatch(addObjectiveComment({ ...data, itemId, lastUpdate: getISOString() }));

        break;
      case "edit":
        if (data && item === "note")
          dispatch(updateNoteComment({ ...data, lastUpdate: getISOString() }));

        if (data && item === "objective")
          dispatch(updateObjectiveComment({ ...data, lastUpdate: getISOString() }));

        break;
      case "delete":
        setToDelete(data);
        setOpenDelete(true);
        break;
      default:
        break;
    }
  };

  const handleDelete = (confirm?: boolean) => {
    if (confirm && toDelete) {
      item === "note" && dispatch(deleteNoteComment(toDelete));
      item === "objective" && dispatch(deleteObjectiveComment(toDelete));
    }
    setOpenDelete(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <FlexBox mt={2} sx={{ justifyContent: "space-between" }}>
        <Typography variant="body1" sx={{ fontWeight: 500, mb: 0.3 }}>
          {t("labels.comments")}
        </Typography>
        <SecondaryText ml={1.5}>{comments.length}</SecondaryText>
      </FlexBox>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ItemComment onSubmit={handleSubmit} loading={loading} />
        </Grid>
        {comments.map((c) => (
          <Grid item xs={12} key={c.id}>
            <ItemComment data={c} onSubmit={handleSubmit} />
          </Grid>
        ))}
      </Grid>
      <ConfirmModal
        open={openDelete}
        onSubmit={handleDelete}
        title={t("pages.notes.deleteComment")}
        content={t("pages.notes.deleteCommentDescription")}
      />
    </Box>
  );
};

export default CommentList;

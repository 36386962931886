import { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { SERIES_COLORS } from "../../helpers/constants";
import { IChartParams, IChartSerie } from "../../types";
import { useSelector } from "react-redux";
import { filtersSelector } from "../../redux/admin/masterdata/selectors";
import { isSameDay } from "date-fns";
import { timeLabelToInt } from "../../helpers/utils";

function getTimeRange(val: number, categories: string[]) {
  const hour = timeLabelToInt(categories[val]);

  if (val === 0) return categories[val];
  else if (val === categories.length) {
    const time = timeLabelToInt(categories[val - 1]);
    return time ? `${time}:00 - ${time + 1}:00` : categories[val];
  } else return hour ? `${hour - 1}:00 - ${hour}:00` : categories[val];
}

interface Props {
  title?: string;
  series: IChartSerie[];
  categories: string[];
  disableFuture?: boolean;
  height?: string | number;
}

const LineChart = ({ title, series, categories, height, disableFuture }: Props) => {
  const filters = useSelector(filtersSelector);
  const [seriesData, setSeriesData] = useState<IChartSerie[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const sameDay = filters.businessDate
    ? isSameDay(new Date(filters.businessDate), new Date())
    : false;

  useEffect(() => {
    const hour = new Date().getHours();
    let newSeries = series.map((e) => ({ ...e }));
    let newCategories = [...categories];
    /** offset series */
    newSeries.forEach((item) => {
      let newData = [...item.data];
      if (disableFuture && sameDay) {
        const index = categories.findIndex((c) => parseInt(c.slice(0, 2)) === hour);
        if (index !== -1) newData = newData.map((e, i) => (i < index ? e : null));
      }

      newData.unshift(0);
      item = { ...item, data: newData };
    });
    /**offset categories */
    const lastHour = timeLabelToInt(newCategories[newCategories.length - 1]);
    if (lastHour === 23) {
      newCategories.push("0:00");
    } else {
      newCategories.push(`${lastHour || 0 + 1}:00`);
    }

    setSeriesData(newSeries);
    setLabels(newCategories);
    // eslint-disable-next-line
  }, [series, categories]);

  const options: ApexOptions = {
    chart: {
      id: "basic-bar",
    },
    title: {
      text: title,
      align: "center",
    },
    xaxis: {
      categories: labels,
    },
    yaxis: {
      title: {
        text: "GCs",
      },
    },
    // colors: SEGMENTS_COLORS,
    colors: [
      function ({ value, seriesIndex, w }: IChartParams) {
        const name = series[seriesIndex].name;
        return SERIES_COLORS[name];
      },
    ],
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    markers: {
      // size: getMarkers(seriesData),
      size: 0,
      fillOpacity: 0.8,
    },
    tooltip: {
      x: {
        formatter: (val) => getTimeRange(val - 1, categories),
      },
    },
  };

  return (
    <ReactApexChart options={options} series={seriesData} type="line" height={height || 350} />
  );
};

export default LineChart;

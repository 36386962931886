import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import Layout from "../layout/Layout";
import PrivateRoute from "./PrivateRoute";
import AreaChecklist from "../pages/activities/AreaChecklist";
import NoteSummary from "../pages/notes/NoteSummary";
import ActivitiesDashboard from "../pages/home/ActivitiesDashboard";
import ManagerLogin from "../pages/home/ManagerLogin";
import Managers from "../pages/user-admin/Managers";
import Schedules from "../pages/user-admin/Schedules";
import StoreAreas from "../pages/user-admin/StoreAreas";
import CustomCheklist from "../pages/user-admin/CustomChecklist";
import Login from "../pages/signIn/Login";
import NoAccess from "../pages/signIn/NoAccess";
import Objectives from "../pages/activities/Objectives";
import PosShift from "../pages/activities/PosShift";
import PreShift from "../pages/activities/PreShift";
import MyActivities from "../pages/activities/MyActivities";
import HourlyReview from "../pages/activities/HourlyReview";
import SalesDashboard from "../pages/home/SalesDashboard";
import MaintenanceScreen from "../pages/signIn/MaintenanceScreen";
import { routes } from "./routes";

import Prototype from "../pages/home/Prototype";

interface Props {
  isAuthenticated: boolean;
}

const Router = ({ isAuthenticated }: Props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<Navigate to={routes.login.path} />} />
        <Route path={routes.login.path} element={<Login isAuthenticated={isAuthenticated} />} />
        <Route path={routes.maintenance.path} element={<MaintenanceScreen />} />
        <Route
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route
            path={routes.main.base.path}
            element={<PrivateRoute isAuthenticated={isAuthenticated} />}
          >
            <Route path={routes.noAccess.path} element={<NoAccess />} />
            <Route path={routes.main.activitiesDashboard.path} element={<ActivitiesDashboard />} />
            <Route path={routes.main.salesDashboard.path} element={<SalesDashboard />} />
            <Route path={routes.main.home.path} element={<ManagerLogin />} />
            <Route path={routes.main.opening.path} element={<AreaChecklist />} />
            <Route path={routes.main.closing.path} element={<AreaChecklist />} />
            <Route path={routes.main.checklist.path} element={<AreaChecklist />} />
            <Route path={routes.main.preShift.path} element={<PreShift />} />
            <Route path={routes.main.myActivities.path} element={<MyActivities />} />
            <Route path={routes.main.objectives.path} element={<Objectives />} />
            <Route path={routes.main.hourlyReview.path} element={<HourlyReview />} />
            <Route path={routes.main.posShift.path} element={<PosShift />} />
            <Route path={routes.main.notesSummary.path} element={<NoteSummary />} />
            <Route path={routes.main.managers.path} element={<Managers />} />
            <Route path={routes.main.schedules.path} element={<Schedules />} />
            <Route path={routes.main.restaurant.path} element={<StoreAreas />} />
            <Route path={routes.main.customChecklist.path} element={<CustomCheklist />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

import React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { INoteItem } from "../types";
import NormalIcon from "@mui/icons-material/Chat";
import PriorityIcon from "@mui/icons-material/Feedback";
import PosShiftIcon from "@mui/icons-material/ContentPasteGo";
import { ListItemButton, SxProps } from "@mui/material";
import { getNoteShiftDescription } from "../helpers/utils";
import { useDispatch } from "react-redux";
import { setSelectedNote, toggleNoteModal } from "../redux/activities/notes/actions";

const nIcon: SxProps = {
  color: "primary.main",
  mr: 0.25,
};

const pIcon: SxProps = {
  color: "primary.main",
};

function sliceDescription(text: string) {
  const limit = 30;
  if (text.length > limit) return `${text.slice(0, limit)}...`;
  return text;
}

interface Props {
  notes: INoteItem[];
}

const NotesMenuList = ({ notes }: Props) => {
  const dispatch = useDispatch();

  const handleClick = (note: INoteItem) => {
    dispatch(setSelectedNote({ note }));
    dispatch(toggleNoteModal());
  };

  function renderIcon(note: INoteItem) {
    if (note.activityTypesId === "POS") return <PosShiftIcon sx={pIcon} />;
    if (note.priority) return <PriorityIcon sx={{ color: "error.main" }} />;
    return <NormalIcon sx={nIcon} />;
  }

  return (
    <>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {notes.map((n, index) => (
          <React.Fragment key={`note-${n.id}`}>
            {index !== 0 && <Divider variant="inset" component="li" />}
            <ListItemButton alignItems="flex-start" onClick={() => handleClick(n)}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "secondary.light" }}>{renderIcon(n)}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={n.title}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {getNoteShiftDescription(n) + " - "}
                    </Typography>
                    {sliceDescription(n.description)}
                  </React.Fragment>
                }
              />
            </ListItemButton>
          </React.Fragment>
        ))}
      </List>
    </>
  );
};

export default NotesMenuList;

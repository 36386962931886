import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import { useTranslation } from "react-i18next";
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  PaletteMode,
  Switch,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ThemeIcon from "@mui/icons-material/BrightnessMedium";
import LanguageIcon from "@mui/icons-material/Language";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { preferencesSelector, appUserSelector } from "../redux/user/selectors";
import { IUserPreferences } from "../types";
import { LANGUAGES } from "../helpers/constants";
import { useDispatch } from "react-redux";
import { setPreferences } from "../redux/user/actions";
import AppStorage from "../services/AppStorage";
import { getScheduleDescription, getUserFullName, stringAvatar } from "../helpers/utils";
import { filtersSelector } from "../redux/admin/masterdata/selectors";
import UserAvatar from "../components/UserAvatar";

const iconStyle: SxProps<Theme> = {
  opacity: 0.85,
  color: "inherit",
  ml: 1,
};

function getChecked(preferences: IUserPreferences | null) {
  if (preferences?.paletteMode === "dark") return true;
  return false;
}

const ProfileMenu = () => {
  const { t, i18n } = useTranslation();
  const { instance } = useMsal();
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector(appUserSelector);
  const preferences = useSelector(preferencesSelector);
  const filters = useSelector(filtersSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [modeChecked, setModeChecked] = useState(getChecked(preferences));
  const [openLanguage, setOpenLanguage] = useState(false);
  const open = Boolean(anchorEl);

  const handleMenuClick: IconButtonProps["onClick"] = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenLanguage(false);
  };

  const getIconColor = (isDisabled?: boolean) => {
    if (theme.palette.mode === "dark" || isDisabled) return "inherit";
    return "primary";
  };

  const toggleMode = (checked: boolean) => {
    if (preferences) {
      const paletteMode: PaletteMode = checked ? "dark" : "light";
      const newPreferences: IUserPreferences = { ...preferences, paletteMode };
      setModeChecked(checked);
      dispatch(setPreferences({ preferences: newPreferences }));
      AppStorage.setUserPreferences(newPreferences);
    }
  };

  const toggleLanguage = () => setOpenLanguage((state) => !state);

  const handleLanguage = (lng: string) => {
    if (preferences) {
      i18n.changeLanguage(lng);
      const newPreferences: IUserPreferences = { ...preferences, language: lng };
      dispatch(setPreferences({ preferences: newPreferences }));
      AppStorage.setUserPreferences(newPreferences);
      setOpenLanguage(false);
    }
  };

  const handleLogout = () => {
    handleClose();
    instance.logoutRedirect();
  };

  return (
    <>
      <IconButton
        onClick={handleMenuClick}
        size="small"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={iconStyle}
      >
        <AccountCircle sx={{ width: 34, height: 34 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 15,
                height: 15,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box mx={2} mt={1}>
          <Box display={"flex"} justifyContent={"center"}>
            <UserAvatar size={100} fontSize="2.9rem">
              {stringAvatar(user)}
            </UserAvatar>
          </Box>
          <Box textAlign={"center"} display={"grid"} mb={2}>
            <Typography variant="h6" height={26}>
              {getUserFullName(user)}
            </Typography>
            <Typography variant="caption" color={"text.secondary"} height={18}>
              {user?.identifier || "-"}
            </Typography>
            <Typography variant="caption">
              {filters.shift && `${t("labels.shift")} ${getScheduleDescription(filters.shift)}`}
            </Typography>
          </Box>
          <List sx={{ display: "inline-grid", borderRadius: 20 }}>
            <ListItemButton onClick={toggleLanguage} sx={{ borderRadius: 20 }}>
              <ListItemIcon sx={{ minWidth: 35 }}>
                <LanguageIcon color={getIconColor()} />
              </ListItemIcon>
              <ListItemText primary={t("labels.language")} />
              {openLanguage ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openLanguage} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {LANGUAGES.map((e) => (
                  <ListItemButton
                    key={e.language}
                    sx={{
                      pl: 7,
                      borderRadius: 20,
                      color: (theme) =>
                        i18n.language === e.language ? theme.palette.primary.main : "inherit",
                    }}
                    onClick={() => handleLanguage(e.language)}
                  >
                    <ListItemText primary={e.label} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
            <ListItem>
              <ListItemIcon sx={{ minWidth: 35 }}>
                <ThemeIcon color={getIconColor()} />
              </ListItemIcon>
              <ListItemText id="switch" primary={t("labels.darkMode")} />
              <Switch
                edge="end"
                onChange={(e, checked) => toggleMode(checked)}
                checked={modeChecked}
                inputProps={{
                  "aria-labelledby": "switch",
                }}
              />
            </ListItem>
            <ListItemButton sx={{ borderRadius: 20 }} onClick={handleLogout}>
              <ListItemIcon sx={{ minWidth: 35 }}>
                <LogoutIcon color={getIconColor()} />
              </ListItemIcon>
              <ListItemText primary={t("labels.logout")} />
            </ListItemButton>
          </List>
        </Box>
      </Menu>
    </>
  );
};

export default ProfileMenu;

import { IShiftSchedules } from "../../../types";
import {
  doneRemoteResource,
  errorRemoteResource,
  initialRemoteResource,
  requestedRemoteResource,
} from "../../utils";
import { Actions, ActionType, ScheduleState } from "./types";

const initialState: ScheduleState = {
  schedules: initialRemoteResource({}),
  currentSchedule: [],
  loading: false,
};

const schedulesReducer = (state: ScheduleState = initialState, action: Actions): ScheduleState => {
  switch (action.type) {
    /** SCHEDULES */
    case ActionType.getSchedulesRequested:
      return {
        ...state,
        schedules: requestedRemoteResource({}),
        loading: true,
      };
    case ActionType.getSchedulesDone:
      return {
        ...state,
        schedules: doneRemoteResource(action.payload.schedules),
        loading: false,
      };
    case ActionType.getSchedulesError:
      return {
        ...state,
        schedules: errorRemoteResource({}),
        loading: false,
      };
    case ActionType.setSchedulesRequested:
      return {
        ...state,
        loading: true,
      };
    case ActionType.setSchedulesDone:
      return {
        ...state,
        schedules: doneRemoteResource(action.payload.schedules as IShiftSchedules),
        loading: false,
      };
    case ActionType.setSchedulesError:
      return {
        ...state,
        loading: false,
      };
    case ActionType.setCurrentSchedule:
      return {
        ...state,
        currentSchedule: action.payload.schedule,
      };
    /** CLEAR DATA */
    case ActionType.clearData:
      return {
        ...state,
        schedules: initialRemoteResource({}),
        currentSchedule: [],
        loading: false,
      };

    default:
      return state;
  }
};

export default schedulesReducer;

import { rsApi, shiftApi } from "../config/axiosInstances";
import { PromiseResult, promiseResultWrapper } from "../helpers/utils";
import {
  IManager,
  IAreaType,
  IScheduleItem,
  IQueryParams,
  ICustomChecklistItem,
  IActivityItem,
} from "../types";
import {
  GetMasterdataResponse,
  GetStorePlanResponse,
  GetActivityTypesResponse,
  GetObjectivesTypesResponse,
  GetHourlyReviewTypesResponse,
  GetAreasResponse,
  GetSchedulesResponse,
  GetManagersResponse,
  ApiResponse,
  GetCustomChecklistsResponse,
  GetAppParametersResponse,
} from "./interfaces";

export interface ManagersResponse {
  data: IManager;
}

export interface CustomChecklistsResponse {
  data: { data: ICustomChecklistItem };
}

export interface CustomActivitiesResponse {
  data: { data: IActivityItem[] };
}

class StorePlanServices {
  /* # # # # # # # # # # # # # # # # # MASTERDATA # # # # # # # # # # # # # # # # # */

  static async getPermissions(): Promise<PromiseResult<any>> {
    return promiseResultWrapper<any>(() => rsApi.get("/permissions"));
  }

  static async getOperativeStructure(): Promise<PromiseResult<GetMasterdataResponse>> {
    return promiseResultWrapper<GetMasterdataResponse>(() => rsApi.get("/op-structures"));
  }

  static async getStorePlan(params: IQueryParams): Promise<PromiseResult<GetStorePlanResponse>> {
    return promiseResultWrapper<any>(() => shiftApi.get("/store_plan", { params }));
  }

  static async getActivityTypes(): Promise<PromiseResult<GetActivityTypesResponse>> {
    return promiseResultWrapper<any>(() => shiftApi.get("/activity_types"));
  }

  static async getObjectivesTypes(): Promise<PromiseResult<GetObjectivesTypesResponse>> {
    return promiseResultWrapper<any>(() => shiftApi.get("/objectives_types"));
  }

  static async getHourlyReviewTypes(
    params: IQueryParams
  ): Promise<PromiseResult<GetHourlyReviewTypesResponse>> {
    const { country, storeAcronym } = params;
    return promiseResultWrapper<any>(() =>
      shiftApi.get("/hourly_activities", { params: { country, storeAcronym } })
    );
  }

  static async getAppParameters(
    params: IQueryParams
  ): Promise<PromiseResult<GetAppParametersResponse>> {
    const { country, storeAcronym } = params;
    return promiseResultWrapper<any>(() =>
      shiftApi.get("/parameters", { params: { country, storeAcronym } })
    );
  }

  /* # # # # # # # # # # # # # # # # # ADMIN # # # # # # # # # # # # # # # # # */

  static async getAreas(params: IQueryParams): Promise<PromiseResult<GetAreasResponse>> {
    return promiseResultWrapper<any>(() => shiftApi.get("/store_area", { params }));
  }

  static async setAreas(
    body: IAreaType[],
    params: IQueryParams
  ): Promise<PromiseResult<ApiResponse>> {
    return promiseResultWrapper<ApiResponse>(() => shiftApi.put(`/store_area`, { body, params }));
  }

  static async getSchedules(params: IQueryParams): Promise<PromiseResult<GetSchedulesResponse>> {
    return promiseResultWrapper<GetSchedulesResponse>(() =>
      shiftApi.get("/store_hour", { params })
    );
  }

  static async setSchedules(
    body: IScheduleItem[],
    params: IQueryParams
  ): Promise<PromiseResult<ApiResponse>> {
    return promiseResultWrapper<ApiResponse>(() => shiftApi.put(`/store_hour`, { body, params }));
  }

  static async getManagers(params: IQueryParams): Promise<PromiseResult<GetManagersResponse>> {
    return promiseResultWrapper<GetManagersResponse>(() =>
      shiftApi.get("/store_manager", { params })
    );
  }

  static async addManager(
    body: IManager,
    params: IQueryParams
  ): Promise<PromiseResult<ManagersResponse>> {
    return promiseResultWrapper<ManagersResponse>(() =>
      shiftApi.post(`/store_manager`, { body, params })
    );
  }

  static async updateManager(
    body: IManager,
    params: IQueryParams
  ): Promise<PromiseResult<ApiResponse>> {
    const id = body.identifier;
    return promiseResultWrapper<ApiResponse>(() =>
      shiftApi.put(`/store_manager/${id}`, { body, params })
    );
  }

  static async deleteManager(
    identifier: number,
    params: IQueryParams
  ): Promise<PromiseResult<ApiResponse>> {
    return promiseResultWrapper<ApiResponse>(() =>
      shiftApi.delete(`/store_manager/${identifier}`, { params })
    );
  }

  static async getCustomChecklists(
    params: IQueryParams
  ): Promise<PromiseResult<GetCustomChecklistsResponse>> {
    const { storeAcronym, country } = params;
    return promiseResultWrapper<GetCustomChecklistsResponse>(() =>
      shiftApi.get("/store_custom_checklist", { params: { storeAcronym, country } })
    );
  }

  static async addCustomChecklist(
    body: ICustomChecklistItem,
    params: IQueryParams
  ): Promise<PromiseResult<CustomChecklistsResponse>> {
    const { country, storeAcronym } = params;
    return promiseResultWrapper<CustomChecklistsResponse>(() =>
      shiftApi.post(`/store_custom_checklist`, {
        body,
        params: { country, storeAcronym },
      })
    );
  }

  static async updateCustomChecklist(
    body: Partial<ICustomChecklistItem>,
    params: IQueryParams
  ): Promise<PromiseResult<CustomChecklistsResponse>> {
    const id = body.id;
    const { country, storeAcronym } = params;
    return promiseResultWrapper<CustomChecklistsResponse>(() =>
      shiftApi.put(`/store_custom_checklist/${id}`, {
        body: { enabled: body.enabled },
        params: { country, storeAcronym },
      })
    );
  }

  static async deleteCustomChecklist(ids: number): Promise<PromiseResult<ApiResponse>> {
    return promiseResultWrapper<ApiResponse>(() => shiftApi.delete(`/store_custom_checklist/${ids}`));
  }

  static async addCustomActivities(
    body: Partial<IActivityItem>[],
    customChecklistId: number
  ): Promise<PromiseResult<CustomActivitiesResponse>> {
    return promiseResultWrapper<CustomActivitiesResponse>(() =>
      shiftApi.post(`/store_custom_activities`, { body, params: { customChecklistId } })
    );
  }

  static async deleteCustomActivities(ids: number[]): Promise<PromiseResult<ApiResponse>> {
    return promiseResultWrapper<ApiResponse>(() => shiftApi.delete(`/store_custom_activities/${ids}`));
  }
}

export default StorePlanServices;

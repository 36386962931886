import { Avatar, Box, Grid, ListItemText, Paper, SxProps, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import ShiftIcon from "@mui/icons-material/PendingActions";
import DayIcon from "@mui/icons-material/Today";
import ScheduleIcon from "@mui/icons-material/Alarm";
import ChangeShiftIcon from "@mui/icons-material/SwitchAccount";
import { ActionButton, FlexBox, SecondaryText } from "../../styles";
import { useNavigate } from "react-router-dom";
import { routes } from "../../router/routes";
import { useSelector } from "react-redux";
import {
  formattedDate,
  formattedTime,
  getScheduleDescription,
  getUserFullName,
} from "../../helpers/utils";
import { filtersSelector } from "../../redux/admin/masterdata/selectors";
import { appUserSelector } from "../../redux/user/selectors";
import StoreDetailsCard from "./StoreDetailsCard";

const lgIcon: SxProps = {
  width: 35,
  height: 35,
  mr: 0.5,
  ml: 0.5,
};

const smIcon: SxProps = {
  opacity: 0.75,
  mr: 0.5,
  width: 30,
  height: 30,
};

const ShiftAvatar = styled(Avatar)(({ theme }) => ({
  width: 48,
  height: 48,
  backgroundColor: theme.palette.secondary.light,
  marginRight: theme.spacing(1),
}));

interface SCProps {
  title: string;
  value: string;
  icon: JSX.Element;
}

const StatCard = ({ title, value, icon }: SCProps) => {
  return (
    <Box px={0.8}>
      <FlexBox>
        {icon}
        <Typography variant="h6" sx={{ fontWeight: 500, opacity: 0.9 }}>
          {title}
        </Typography>
      </FlexBox>
      <SecondaryText ml={0.4}>{value}</SecondaryText>
    </Box>
  );
};

const CurrentShiftCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(appUserSelector);
  const filters = useSelector(filtersSelector);

  const handleChangeShift = () => {
    navigate(routes.main.home.path);
  };

  return (
    <Box mt={1}>
      <StoreDetailsCard filters={filters} />
      <Box component={Paper} p={2} pt={1} mt={1}>
        <Grid container spacing={1} justifyContent={"space-between"}>
          <Grid item xs={12} mb={2}>
            <FlexBox sx={{ justifyContent: "space-between" }}>
              <FlexBox>
                <ShiftAvatar>
                  <ShiftIcon sx={lgIcon} color="primary" />
                </ShiftAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h6">
                      {filters.shift &&
                        `${t("labels.shift")} ${getScheduleDescription(filters.shift)}`}
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ opacity: 0.6, fontWeight: 500, mt: -0.5 }}>
                      {getUserFullName(user)}
                    </Typography>
                  }
                />
              </FlexBox>
              <ActionButton
                variant="contained"
                onClick={handleChangeShift}
                startIcon={<ChangeShiftIcon />}
                sx={{ px: 2 }}
              >
                {t("labels.changeShift")}
              </ActionButton>
            </FlexBox>
          </Grid>
          <Grid item>
            <StatCard
              title={t("labels.day")}
              value={formattedDate(filters.businessDate)}
              icon={<DayIcon sx={smIcon} />}
            />
          </Grid>
          <Grid item>
            <StatCard
              title={t("labels.schedule")}
              value={formattedTime(filters.shift)}
              icon={<ScheduleIcon sx={smIcon} />}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CurrentShiftCard;

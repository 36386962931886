import React from "react";
import {
  Box,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Radio,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { IAreaType } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { setAreasRequested } from "../../redux/admin/areas/actions";
import { areasDataSelector, loadingSelector } from "../../redux/admin/areas/selectors";
import { ActionButton, FlexBox, SecondaryText } from "../../styles";
import { getAreaDescription, getAreasCompleted } from "../../helpers/utils";
import { setNavigateController } from "../../redux/app/actions";
import { isAppOnlineSelector, navigateSelector } from "../../redux/app/selectors";
import SettingsChangesMsg from "../../components/SettingsChangesMsg";
import SaveIcon from "@mui/icons-material/Save";

function compare(a: IAreaType, b: IAreaType) {
  if (a.areaTypeId === "GRL") return -1;
  if (b.areaTypeId === "GRL") return 1;
  return 0;
}

const StoreAreas = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAppOnline = useSelector(isAppOnlineSelector);
  const data = useSelector(areasDataSelector);
  const loading = useSelector(loadingSelector);
  const navigateController = useSelector(navigateSelector);
  const [areas, setAreas] = React.useState<IAreaType[]>([]);

  React.useEffect(() => {
    if (data.length > 0) {
      const newState = data?.map((e) => ({ ...e }));
      setAreas(newState.sort(compare));
    }
    // eslint-disable-next-line
  }, [data]);

  const handleSubmit = () => {
    dispatch(setAreasRequested(areas));
    dispatch(setNavigateController({ navigateController: {} }));
  };

  React.useEffect(() => {
    if (navigateController.path && navigateController.saveChanges === true) {
      handleSubmit();
      dispatch(
        setNavigateController({
          navigateController: { ...navigateController, hasChanges: false, saveChanges: false },
        })
      );
    }
    // eslint-disable-next-line
  }, [navigateController]);

  const handleChange = (index: number, checked: boolean) => {
    const newState = [...areas];
    newState[index].areaEnabled = checked;
    setAreas(newState);
    !navigateController.hasChanges &&
      dispatch(setNavigateController({ navigateController: { hasChanges: true } }));
  };

  return (
    <Container>
      <Box p={2} textAlign={"center"}>
        <Typography variant="h4">{t("pages.restaurant.title")}</Typography>
        <SecondaryText variant="body1" mb={3}>
          {t("pages.restaurant.subtitle")}
        </SecondaryText>
      </Box>
      <Box component={Paper} px={4} py={2.5}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FlexBox sx={{ justifyContent: "space-between" }}>
                <Typography variant="h5">{t("pages.restaurant.description")}</Typography>
                <ActionButton
                  variant="contained"
                  sx={{ minWidth: 120 }}
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={loading || !isAppOnline}
                  startIcon={<SaveIcon />}
                >
                  {t("labels.save")}
                </ActionButton>
              </FlexBox>
              <Divider sx={{ my: 1.5 }} />
            </Grid>
            {areas?.map((area, index) => (
              <Grid item xs={12} lg={3} key={area.areaTypeId}>
                <FormControlLabel
                  disabled={area.areaTypeId === "GRL"}
                  control={
                    <Radio
                      checked={area.areaEnabled}
                      onClick={() => handleChange(index, !area.areaEnabled)}
                    />
                  }
                  label={getAreaDescription(area)}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
        <SettingsChangesMsg show={getAreasCompleted(data)} />
      </Box>
    </Container>
  );
};

export default StoreAreas;

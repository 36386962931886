import IconButton from "@mui/material/IconButton";
import { useEffect, useMemo, useState } from "react";
import Menu from "@mui/material/Menu";
import { useTranslation } from "react-i18next";
import { Badge, BadgeProps, Box, SxProps, Theme, Typography, styled } from "@mui/material";
import NotesIcon from "@mui/icons-material/LibraryBooks";
import NotesMenuList from "../../components/NotesMenuList";
import { ActionButton, FlexBox } from "../../styles";
import { routes } from "../../router/routes";
import { useDispatch, useSelector } from "react-redux";
import { notesSelector } from "../../redux/activities/notes/selectors";
import { navigateSelector } from "../../redux/app/selectors";
import { setNavigateController } from "../../redux/app/actions";
import { compareNotesPriority } from "../../helpers/utils";
import { useLocation } from "react-router-dom";
import { storePlanSelector } from "../../redux/storePlan/selectors";
import { objectivesSelector } from "../../redux/activities/objectives/selectors";
import { RemoteResourceState } from "../../redux/utils";

const iconStyle: SxProps<Theme> = {
  opacity: 0.85,
  color: "inherit",
  ml: 1,
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 2,
    top: 8,
  },
}));

const NotesMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigateController = useSelector(navigateSelector);
  const storePlan = useSelector(storePlanSelector);
  const objectivesState = useSelector(objectivesSelector);
  const notesState = useSelector(notesSelector);
  const activitiesLoaded =
    storePlan.state === RemoteResourceState.REMOTE_RESOURCE_LOADED &&
    objectivesState.state === RemoteResourceState.REMOTE_RESOURCE_LOADED &&
    notesState.state === RemoteResourceState.REMOTE_RESOURCE_LOADED;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isRead, setIsRead] = useState(false);
  // const disableBtn = activitiesLoaded < 2;
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 90;
  const showGotoNotes = Boolean(storePlan?.data) && !location.pathname.includes("notes-summary");

  const items = useMemo(() => {
    const result = notesState.data.filter((e) => ["active", "pending"].includes(e.state));
    return result.sort(compareNotesPriority);
  }, [notesState.data]);

  useEffect(() => {
    if (storePlan.data) setIsRead(false);
  }, [storePlan.data]);

  const handleNotesClick = () => {
    setAnchorEl(null);
    dispatch(
      setNavigateController({
        navigateController: { ...navigateController, path: routes.main.notesSummary.path },
      })
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledBadge badgeContent={isRead || !activitiesLoaded ? 0 : items.length} color="secondary">
        <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setIsRead(true);
          }}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          sx={iconStyle}
          disabled={!activitiesLoaded}
        >
          <NotesIcon sx={{ width: 25, height: 25 }} />
        </IconButton>
      </StyledBadge>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 10,
                width: 15,
                height: 15,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {items.length > 0 ? (
          <Box minWidth={320}>
            <FlexBox sx={{ justifyContent: "space-between", py: 1, px: 2 }}>
              <Typography variant="h6">{t("labels.activeNotes")}</Typography>
              {showGotoNotes && (
                <ActionButton
                  variant="text"
                  onClick={handleNotesClick}
                  sx={{ px: 1, textDecoration: "underline" }}
                >
                  {t("labels.goToNotes")}
                </ActionButton>
              )}
            </FlexBox>
            <Box sx={{ overflow: "auto", maxHeight: ITEM_HEIGHT * 4.5 }}>
              <NotesMenuList notes={items} />
            </Box>
          </Box>
        ) : (
          <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ p: 2 }}>{t("labels.noNotes")}</Typography>
            {showGotoNotes && (
              <ActionButton
                variant="text"
                onClick={handleNotesClick}
                sx={{ px: 1, textDecoration: "underline" }}
              >
                {t("labels.goToNotes")}
              </ActionButton>
            )}
          </Box>
        )}
      </Menu>
    </>
  );
};

export default NotesMenu;

import { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { IObjectiveItem } from "../../types";
import { palette_primary_medium, secondary_main } from "../../theme/theme";
import { Skeleton } from "@mui/material";
import { FlexBox } from "../../styles";
import { numberFormatter } from "../../helpers/utils";
import { useTranslation } from "react-i18next";

interface Props {
  objectives: IObjectiveItem[];
}

const SemiDonutChart = ({ objectives }: Props) => {
  const { t } = useTranslation();
  const [series, setSeries] = useState<number[]>([]);

  useEffect(() => {
    let completed = 0;
    let pendings = 0;

    objectives.forEach((o) => {
      if (o.objectiveAchieved) completed++;
      else pendings++;
    });
    setSeries([completed, pendings]);
  }, [objectives]);

  const options: ApexOptions = {
    chart: {
      type: "donut",
    },
    labels: [t("labels.reached"), t("labels.pending")],
    colors: [secondary_main, palette_primary_medium],
    dataLabels: {
      formatter(val, opts) {
        if (opts.seriesIndex > 0) return "";
        else return `${numberFormatter(val as number, 1, 1)}%`;
      },
      style: {
        fontSize: "17px",
        fontFamily: "Roboto, Arial, sans-serif",
        fontWeight: "bold",
        colors: ["#FFF"],
      },
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              fontFamily: "Roboto, Arial, sans-serif",
              fontWeight: 400,
              color: undefined,
              offsetY: -30,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: true,
              fontSize: "18px",
              fontFamily: "Roboto, Arial, sans-serif",
              fontWeight: 600,
              color: undefined,
              offsetY: -20,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: false,
              label: `${t("labels.objective")}s`,
              fontSize: "14px",
              fontFamily: "Roboto, Arial, sans-serif",
              fontWeight: 400,
              color: "#373d3f",
              formatter: function (w) {
                const completed = w.globals.seriesTotals[0] || 0;
                const pendings = w.globals.seriesTotals[1] || 0;
                return `${t("labels.reached")}s ${completed}/${completed + pendings}`;
              },
            },
          },
        },
      },
    },
    grid: {
      padding: {
        bottom: -120,
      },
    },
    responsive: [
      {
        breakpoint: 800,
        options: {
          chart: {
            height: 200,
          },
        },
      },
      {
        breakpoint: 1024,
        options: {
          chart: {
            height: 250,
          },
        },
      },
      {
        breakpoint: 1280,
        options: {
          chart: {
            height: 300,
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            height: 350,
          },
        },
      },
      {
        breakpoint: 1600,
        options: {
          chart: {
            height: 400,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
  };

  if (objectives.length === 0)
    return (
      <FlexBox sx={{ placeContent: "center", mt: 2 }}>
        <Skeleton variant="circular" width={200} height={200} />
      </FlexBox>
    );

  return <ReactApexChart options={options} series={series} type="donut" height={500} />;
};

export default SemiDonutChart;

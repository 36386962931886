import React from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  SxProps,
  Typography,
} from "@mui/material";
import { INoteItem } from "../../types";
import DateIcon from "@mui/icons-material/Today";
import PrivateIcon from "@mui/icons-material/PendingActions";
import PublicIcon from "@mui/icons-material/Storefront";
import ShiftIcon from "@mui/icons-material/PendingActions";
import PriorityIcon from "@mui/icons-material/Error";
import CommentsIcon from "@mui/icons-material/ChatBubbleOutline";
import { formattedDate, getNoteShiftDescription } from "../../helpers/utils";
import IconAvatar from "../IconAvatar";
import { Draggable } from "react-beautiful-dnd";
import { FlexBox, StyledBadge } from "../../styles";

const cardStyle: SxProps = {
  position: "relative",
  width: "100%",
  borderRadius: 3,
  cursor: "pointer",
  "&:hover": {
    opacity: 0.8,
  },
};

const noteTypeColor: SxProps = {
  opacity: 0.8,
  color: "common.black",
};

const infoColor: SxProps = {
  opacity: 0.5,
};

const cardContent: SxProps = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const loader: SxProps = {
  opacity: 0.8,
  ml: 1,
};

interface Props {
  data?: INoteItem;
  state: "active" | "pending" | "done";
  index: number;
  loading?: boolean;
  onClick: (note?: INoteItem) => void;
  onChangeState?: (action: string) => void;
}

const NoteCard = ({ data, state, index, loading, onClick }: Props) => {
  const handleClick = () => {
    if (data) onClick(data);
  };

  return (
    <Draggable draggableId={`${data?.id}`} index={index}>
      {(provided) => {
        return (
          <div
            className="drag-item"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Card sx={cardStyle} elevation={3} onClick={handleClick}>
              <CardContent>
                <Box sx={cardContent}>
                  <FlexBox>
                    <Typography variant="h6">{data?.title}</Typography>
                    {loading && <CircularProgress size={18} sx={loader} />}
                  </FlexBox>
                  <IconAvatar size={40}>
                    {data?.private ? (
                      <PrivateIcon sx={noteTypeColor} />
                    ) : (
                      <PublicIcon sx={noteTypeColor} />
                    )}
                  </IconAvatar>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Typography>{data?.description}</Typography>
              </CardContent>
              <CardActions>
                <Box sx={cardContent} mb={1} px={0.5}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <DateIcon sx={infoColor} />
                    <Typography ml={0.5} sx={infoColor}>
                      {formattedDate(data?.lastUpdateDate)}
                    </Typography>
                    <ShiftIcon sx={{ ...infoColor, ml: 2 }} />
                    <Typography ml={0.5} sx={infoColor}>
                      {data && getNoteShiftDescription(data).slice(0, 1)}
                    </Typography>
                    <StyledBadge badgeContent="" color="secondary" sx={{ ml: 2.5 }}>
                      <CommentsIcon sx={infoColor} />
                    </StyledBadge>
                    <Typography ml={1} sx={infoColor}>
                      {data?.comments?.length || 0}
                    </Typography>
                  </Box>
                  {data?.priority && (
                    <PriorityIcon color="error" sx={{ width: 30, height: 30, mr: 1.25 }} />
                  )}
                </Box>
              </CardActions>
            </Card>
          </div>
        );
      }}
    </Draggable>
  );
};

export default NoteCard;

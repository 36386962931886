import { takeLatest, call, put } from "redux-saga/effects";
import StorePlanServices from "../../../services/StorePlanServices";
import { PromiseResult, getFormattedMasterdata } from "../../../helpers/utils";
import { AlertCode, IHttpRequestError, IMasterdata } from "../../../types";
import { getMasterdataDone, getMasterdataError } from "./actions";
import { ActionType, GetMasterdataRequestedAction } from "./types";
import { setSnackbar } from "../../app/actions";
import { GetMasterdataResponse } from "../../../services/interfaces";

function* getMasterdataSagas(action: GetMasterdataRequestedAction) {
  try {
    const {
      result: getMasterdataResponse,
      hasError,
      error,
    }: PromiseResult<GetMasterdataResponse> = yield call(StorePlanServices.getOperativeStructure);
    if (hasError || !getMasterdataResponse) throw error;
    const masterdata: IMasterdata = getFormattedMasterdata(getMasterdataResponse.data);
    yield put(getMasterdataDone({ masterdata }));
  } catch (error) {
    yield put(getMasterdataError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.MASTERDATA_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

const adminSagas = [takeLatest(ActionType.getMasterdataRequested, getMasterdataSagas)];

export default adminSagas;

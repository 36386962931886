export const routes = {
  default: { path: "/login" },
  login: { path: "/login" },
  noAccess: { path: "/no-access" },
  maintenance: { path: "/maintenance" },
  main: {
    base: { path: "/" },
    home: { path: "/shift-plan" },
    activitiesDashboard: { path: "/activities-dashboard" },
    salesDashboard: { path: "/sales-dashboard" },
    opening: { path: "/opening" },
    closing: { path: "/closing" },
    preShift: { path: "/pre-shift" },
    objectives: { path: "/objectives" },
    positioning: { path: "/positioning" },
    profitProyections: { path: "/profit-proyections" },
    checklist: { path: "/checklist" },
    hourlyReview: { path: "/hourly-review" },
    posShift: { path: "/pos-shift" },
    notesSummary: { path: "/notes-summary" },
    newShift: { path: "/new-shift" },
    managers: { path: "/managers" },
    schedules: { path: "/schedules" },
    restaurant: { path: "/restaurant" },
    customChecklist: { path: "/custom-checklist" },
    myActivities: { path: "/my-activities" },
  },
};

export const restrictedRoutes = ["/settings", "/manager", "/schedules", "/restaurant"];

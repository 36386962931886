import React from "react";
import { Box, Button, ButtonGroup, Divider, Paper, Skeleton, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { IObjectiveItem } from "../../types";
import ObjectiveCard from "../../components/cards/ObjectiveCard";
import AllIcon from "@mui/icons-material/LibraryBooks";
import { useTranslation } from "react-i18next";
import ObjectiveFormModal from "../../components/modals/ObjectiveFormModal";
import { ActionButton, FlexBox, SecondaryText } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoadingObjectivesSelector,
  objectivesDataSelector,
} from "../../redux/activities/objectives/selectors";
import {
  addObjectiveRequested,
  updateObjectiveRequested,
} from "../../redux/activities/objectives/actions";
import { isAppOnlineSelector } from "../../redux/app/selectors";
import { objectivesTypesDataSelector } from "../../redux/storePlan/selectors";
import ObjectiveModal from "../../components/modals/ObjectiveModal";
import SemiDonutChart from "../../components/charts/SemiDonutChart";
import { setSelectedNote, toggleNoteModal } from "../../redux/activities/notes/actions";
import { compareObjectives } from "../../helpers/utils";

/**
 * Los objetivos son diarios y por turno, no se obtienen objetivos de otros días/turnos.
 * Utiliza los datos maestros de objetivos para completar el formulario.
 * Operaciones: alta y modificación de objetivos.
 * @activityType OBJ
 */

const Objectives = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAppOnline = useSelector(isAppOnlineSelector);
  const objectiveTypes = useSelector(objectivesTypesDataSelector);
  const data = useSelector(objectivesDataSelector);
  const loading = useSelector(isLoadingObjectivesSelector);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [objectives, setObjectives] = React.useState<IObjectiveItem[]>([]);
  const [selected, setSelected] = React.useState<number>();
  const [selectedObj, setSelectedObj] = React.useState<IObjectiveItem | null>(null);

  React.useEffect(() => {
    const newState: IObjectiveItem[] = data?.map((e) => ({ ...e }));
    const newObj = newState.find((o) => o.id === selectedObj?.id) || null;
    setObjectives(newState.sort(compareObjectives));
    setSelectedObj(newObj);

    // eslint-disable-next-line
  }, [data]);

  const handleOpen = () => {
    setSelected(undefined);
    setOpen(true);
  };

  const handleSubmit = (newData?: Partial<IObjectiveItem>, isDeleting?: boolean) => {
    if (isDeleting) {
      // todo delete
    } else if (newData) {
      dispatch(addObjectiveRequested(newData));
    }
    setOpen(false);
    setSelected(undefined);
  };

  const handleAchieved = (newData: Partial<IObjectiveItem>) => {
    setSelected(newData.id);
    dispatch(updateObjectiveRequested(newData));
  };

  const handleClick = (obj: IObjectiveItem | null) => {
    setOpenModal(Boolean(obj));
    setSelectedObj(obj);
  };

  const handleNote = () => {
    dispatch(setSelectedNote({ note: null }));
    dispatch(toggleNoteModal());
  };

  return (
    <Container maxWidth={false}>
      <FlexBox sx={{ justifyContent: "space-between", mb: 2 }}>
        <FlexBox>
          <ButtonGroup>
            <Button variant="contained" startIcon={<AllIcon />}>
              {t("pages.objectives.title")}
            </Button>
          </ButtonGroup>
        </FlexBox>
        <ActionButton
          variant="contained"
          size="medium"
          sx={{ ml: 2 }}
          onClick={handleOpen}
          loading={loading}
          disabled={loading || !isAppOnline}
        >
          {`+ ${t("labels.objective").toUpperCase()}`}
        </ActionButton>
      </FlexBox>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box component={Paper} p={2} pb={4}>
            <Typography variant="h6" textAlign={"center"} mb={2}>
              {t("labels.inProgress")}
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{ overflow: "auto", maxHeight: "calc(100vh - 258px)", pr: 2, pb: 3 }}
            >
              {objectives.length === 0 && (
                <Box width={"100%"} mt={5.8} px={2}>
                  <Skeleton animation="wave" height={30} width="20%" />
                  <Skeleton animation="wave" height={30} width="60%" />
                  <Skeleton animation="wave" height={30} width="60%" />
                  <SecondaryText variant="body1" sx={{ mt: 2, textAlign: "center" }}>
                    {t("labels.noObjectives")}
                  </SecondaryText>
                </Box>
              )}

              {objectives.map((i, index) => (
                <Grid item xs={12} key={i.id}>
                  <Divider sx={{ mb: 2 }} flexItem />
                  <ObjectiveCard
                    data={i}
                    onClick={handleClick}
                    onChangeAchieved={handleAchieved}
                    loading={loading && i.id === selected}
                    selected={selected}
                    isAppOnline={isAppOnline}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box component={Paper} py={2} textAlign={"center"}>
            <Typography variant="h6">{t("labels.myObjectives")}</Typography>
            <SemiDonutChart objectives={objectives} />
            {objectives.length > 0 && (
              <ActionButton
                variant="contained"
                sx={{ px: 4 }}
                onClick={handleNote}
                loading={loading}
                disabled={loading || !isAppOnline}
              >
                {`+ ${t("labels.addNote").toUpperCase()}`}
              </ActionButton>
            )}
          </Box>
        </Grid>
      </Grid>
      <ObjectiveFormModal
        objectivesList={data}
        objectiveTypes={objectiveTypes}
        open={open}
        onSubmit={handleSubmit}
      />
      <ObjectiveModal open={openModal} objectiveData={selectedObj} setOpen={setOpenModal} />
    </Container>
  );
};

export default Objectives;

import React from "react";
import { Box, Container, ListItemButton, Paper, Switch } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { ActionButton, SecondaryText } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { loadingSelector, managersDataSelector } from "../../redux/admin/managers/selectors";
import { IManager } from "../../types";
import ManagerFormModal from "../../components/modals/ManagerFormModal";
import {
  addManagerRequested,
  deleteManagerRequested,
  updateManagerRequested,
} from "../../redux/admin/managers/actions";
import ScreenLoader from "../../components/ScreenLoader";
import { stringAvatar } from "../../helpers/utils";
import UserAvatar from "../../components/UserAvatar";
import { isAppOnlineSelector } from "../../redux/app/selectors";

const PrimaryStyle = styled(Typography)({
  fontSize: "1.15rem",
});

const Managers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAppOnline = useSelector(isAppOnlineSelector);
  const data = useSelector(managersDataSelector);
  const loading = useSelector(loadingSelector);
  const [managers, setManagers] = React.useState<IManager[]>([]);
  const [openForm, setOpenForm] = React.useState(false);
  const [selected, setSelected] = React.useState<IManager>();

  React.useEffect(() => {
    const newState: IManager[] = data?.map((e) => ({ ...e }));
    setManagers(newState);
  }, [data]);

  const handleSubmit = (data?: IManager, action?: "edit" | "delete") => {
    if (data) {
      switch (action) {
        case "edit":
          dispatch(updateManagerRequested(data));
          break;
        case "delete":
          dispatch(deleteManagerRequested(data.identifier));
          break;
        default:
          dispatch(addManagerRequested(data));
          break;
      }
    }
    setOpenForm(false);
    setSelected(undefined);
  };

  const handleClick = (manager: IManager) => {
    setSelected(manager);
    setOpenForm(true);
  };

  return (
    <Container>
      <Box p={2} mb={3} textAlign={"center"}>
        <Typography variant="h4">{t("pages.managers.title")}</Typography>
        <SecondaryText variant="body1">{t("pages.managers.subtitle")}</SecondaryText>
      </Box>
      <Box component={Paper} px={2}>
        <List sx={{ width: "100%" }}>
          <ListItem
            alignItems="center"
            secondaryAction={
              <ActionButton
                variant="contained"
                onClick={() => setOpenForm(true)}
                loading={loading}
                disabled={loading || !isAppOnline}
                startIcon={<AddIcon />}
                sx={{ minWidth: 110 }}
              >
                {t("labels.add")}
              </ActionButton>
            }
          >
            <ListItemText
              primary={<Typography variant="h5">{t("pages.managers.tableHeader")}</Typography>}
            />
          </ListItem>
          <Divider component="li" sx={{ mx: 2 }} />
        </List>
        <List>
          {managers?.map((m) => {
            return (
              <ListItem
                key={m.identifier}
                disablePadding
                secondaryAction={
                  <Switch
                    checked={m.active}
                    sx={{ opacity: 0.75 }}
                    onClick={() => handleClick(m)}
                  />
                }
              >
                <ListItemButton disabled={loading} onClick={() => handleClick(m)}>
                  <ListItemAvatar>
                    <UserAvatar>{stringAvatar(m)}</UserAvatar>
                  </ListItemAvatar>
                  <ListItemText
                    id={`${m.identifier}`}
                    primary={<PrimaryStyle>{`${m.name} ${m.lastname}`}</PrimaryStyle>}
                    secondary={m.identifier}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <ScreenLoader loading={loading} />
      </Box>
      <ManagerFormModal
        open={openForm}
        isAppOnline={isAppOnline}
        onSubmit={handleSubmit}
        data={selected}
        managers={managers}
      />
    </Container>
  );
};

export default Managers;

import { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material";
import SidebarNav from "./SidebarNav";
import Copyright from "./Copyright";
import { useSelector } from "react-redux";
import { isSidebarOpenSelector } from "../redux/app/selectors";
import { appUserSelector } from "../redux/user/selectors";
import { useNavigate } from "react-router-dom";
import { routes } from "../router/routes";
import { objectivesSelector } from "../redux/activities/objectives/selectors";
import { storePlanSelector } from "../redux/storePlan/selectors";
import { notesSelector } from "../redux/activities/notes/selectors";
import { RemoteResourceState } from "../redux/utils";

const drawerWidth = 200;

const ListContainer = styled("div")({
  overflowX: "hidden",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

const DrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, property }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: property ? drawerWidth : "1px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: property ? theme.spacing(7) : "1px",
      [theme.breakpoints.up("sm")]: {
        width: property ? theme.spacing(7) : "1px",
      },
    }),
  },
}));

interface Props {
  isAppOnline: boolean;
}

const Sidebar = ({ isAppOnline }: Props) => {
  const navigate = useNavigate();
  const isSidebarOpen = useSelector(isSidebarOpenSelector);
  const storePlanState = useSelector(storePlanSelector);
  const objectivesState = useSelector(objectivesSelector);
  const notesState = useSelector(notesSelector);
  const user = useSelector(appUserSelector);

  const activitiesLoaded =
    storePlanState.state === RemoteResourceState.REMOTE_RESOURCE_LOADED &&
    objectivesState.state === RemoteResourceState.REMOTE_RESOURCE_LOADED &&
    notesState.state === RemoteResourceState.REMOTE_RESOURCE_LOADED;
  const showSidebar = user?.rol === "admin" || activitiesLoaded ? true : undefined;

  useEffect(() => {
    if (showSidebar && user?.rol === "manager") navigate(routes.main.activitiesDashboard.path);
    // eslint-disable-next-line
  }, [showSidebar]);

  return (
    <DrawerStyled
      variant={"permanent"}
      open={isSidebarOpen}
      property={showSidebar && "showSidebar"}
    >
      <Toolbar />
      <ListContainer>
        <SidebarNav isAppOnline={isAppOnline} />
        {isSidebarOpen && <Copyright yearSecondLine />}
      </ListContainer>
    </DrawerStyled>
  );
};

export default Sidebar;

import { ICommentItem, IObjectiveItem } from "../../../types";
import {
  doneRemoteResource,
  errorRemoteResource,
  initialRemoteResource,
  requestedRemoteResource,
} from "../../utils";
import { Actions, ActionType, ObjectivesState } from "./types";

const initialState: ObjectivesState = {
  objectives: initialRemoteResource([]),
  objectiveError: false,
  commentError: false,
  loadingObjectives: false,
  loadingComment: false,
};

const addObjective = (objectives: IObjectiveItem[], objective: IObjectiveItem) => {
  const newObjectives = [...objectives, objective];
  return newObjectives;
};

const updateObjectives = (objectives: IObjectiveItem[], objective: IObjectiveItem) => {
  const index = objectives.findIndex((i) => i.id === objective.id);
  if (index !== -1) {
    let newObjectives = [...objectives];
    newObjectives[index] = { ...objective };
    return newObjectives;
  } else {
    return objectives;
  }
};

const deleteObjective = (objectives: IObjectiveItem[], id: number) => {
  const newObjectives = [...objectives.filter((i) => i.id !== id)];
  return newObjectives;
};

const handleAddComment = (state: ObjectivesState, comment: ICommentItem): ObjectivesState => {
  const objectives = state.objectives.data;
  const noteIndex = objectives.findIndex((n) => n.id === comment.itemId);
  if (noteIndex !== -1) {
    let newObjectives: IObjectiveItem[] = objectives.map((n, index) => {
      let newCmts: ICommentItem[] = n.comments.map((c) => ({ ...c }));
      if (noteIndex === index) {
        newCmts.push(comment);
      }
      return { ...n, comments: newCmts };
    });
    return { ...state, objectives: doneRemoteResource(newObjectives) };
  } else return { ...state };
};

const handleUpdateComment = (state: ObjectivesState, comment: ICommentItem): ObjectivesState => {
  const objectives = state.objectives.data;
  const noteIndex = objectives.findIndex((n) => n.id === comment.itemId);
  if (noteIndex !== -1) {
    let newObjectives: IObjectiveItem[] = objectives.map((n, index) => {
      let newCmts: ICommentItem[] = n.comments.map((c) => ({ ...c }));
      if (noteIndex === index) {
        const cmtIndex = newCmts.findIndex((c) => c.id === comment.id);
        if (cmtIndex !== -1) newCmts[cmtIndex] = { ...comment };
      }
      return { ...n, comments: newCmts };
    });
    return { ...state, objectives: doneRemoteResource(newObjectives) };
  } else return { ...state };
};

const handleDeleteComment = (state: ObjectivesState, comment: ICommentItem): ObjectivesState => {
  const objectives = state.objectives.data;
  const noteIndex = objectives.findIndex((n) => n.id === comment.itemId);
  if (noteIndex !== -1) {
    let newObjectives: IObjectiveItem[] = objectives.map((n, index) => {
      let newCmts: ICommentItem[] = n.comments.map((c) => ({ ...c }));
      if (noteIndex === index) newCmts = newCmts.filter((c) => c.id !== comment.id);
      return { ...n, comments: newCmts };
    });
    return { ...state, objectives: doneRemoteResource(newObjectives) };
  } else return { ...state };
};

/** REDUCER */

const activityReducer = (
  state: ObjectivesState = initialState,
  action: Actions
): ObjectivesState => {
  switch (action.type) {
    /** OBJECTIVES */
    case ActionType.getObjectivesRequested:
      return {
        ...state,
        objectives: requestedRemoteResource([]),
        loadingObjectives: true,
      };
    case ActionType.getObjectivesDone:
      return {
        ...state,
        objectives: doneRemoteResource(action.payload.objective),
        loadingObjectives: false,
      };
    case ActionType.getObjectivesError:
      return {
        ...state,
        objectives: errorRemoteResource([]),
        loadingObjectives: false,
      };
    case ActionType.addObjectiveRequested:
      return {
        ...state,
        loadingObjectives: true,
      };
    case ActionType.addObjectiveDone:
      return {
        ...state,
        objectives: doneRemoteResource(
          addObjective(state.objectives.data || [], action.payload.objective as IObjectiveItem)
        ),
        loadingObjectives: false,
      };
    case ActionType.addObjectiveError:
      return {
        ...state,
        loadingObjectives: false,
      };
    case ActionType.updateObjectiveRequested:
      return {
        ...state,
        loadingObjectives: true,
      };
    case ActionType.updateObjectiveDone:
      return {
        ...state,
        objectives: doneRemoteResource(
          updateObjectives(state.objectives.data || [], action.payload.objective as IObjectiveItem)
        ),
        loadingObjectives: false,
      };
    case ActionType.updateObjectiveError:
      return {
        ...state,
        loadingObjectives: false,
      };
    case ActionType.deleteObjectiveRequested:
      return {
        ...state,
        loadingObjectives: true,
      };
    case ActionType.deleteObjectiveDone:
      return {
        ...state,
        objectives: doneRemoteResource(
          deleteObjective(state.objectives.data || [], action.payload.id)
        ),
        loadingObjectives: false,
      };
    case ActionType.deleteObjectiveError:
      return {
        ...state,
        loadingObjectives: false,
      };
    /** COMMENTS */
    case ActionType.addCommentRequested:
      return {
        ...state,
        loadingComment: true,
        commentError: false,
      };
    case ActionType.addCommentDone:
      return {
        ...handleAddComment(state, action.payload.comment as ICommentItem),
        loadingComment: false,
        commentError: false,
      };
    case ActionType.addCommentError:
      return {
        ...state,
        loadingComment: false,
        commentError: true,
      };
    case ActionType.updateCommentRequested:
      return {
        ...state,
        loadingComment: true,
        commentError: false,
      };
    case ActionType.updateCommentDone:
      return {
        ...handleUpdateComment(state, action.payload.comment as ICommentItem),
        loadingComment: false,
        commentError: false,
      };
    case ActionType.updateCommentError:
      return {
        ...state,
        loadingComment: false,
        commentError: true,
      };
    case ActionType.deleteCommentRequested:
      return {
        ...state,
        loadingComment: true,
        commentError: false,
      };
    case ActionType.deleteCommentDone:
      return {
        ...handleDeleteComment(state, action.payload.comment as ICommentItem),
        loadingComment: false,
        commentError: false,
      };
    case ActionType.deleteCommentError:
      return {
        ...state,
        loadingComment: false,
        commentError: true,
      };
    case ActionType.clearObjectives:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default activityReducer;

import { Action } from "redux";
import { ActionPayload } from "../actions";
import { IAlertMessage, INavigateController, ISyncState } from "../../types";

export interface AppState {
  isAppOnline: boolean;
  isSidebarOpen: boolean;
  syncRequired: boolean;
  syncFailed: boolean;
  alert: IAlertMessage | undefined;
  snackbar: IAlertMessage | undefined;
  navitateController: INavigateController;
}

export enum ActionType {
  setIsAppOnline = "app/set-is-apponline",
  toggleSidebar = "app/toggle-sidebar",
  setSyncRequired = "app/set-sync-requitred",
  setSyncFailed = "app/set-sync-error",
  setAlert = "app/set-alert",
  consumeAlert = "app/consume-alert",
  setSnackbar = "app/set-snackbar",
  consumeSnackbar = "app/consume-snackbar",
  setNavigateController = "app/set-navigate-controller",
}

export interface SetIsAppOnlineAction
  extends ActionPayload<ActionType.setIsAppOnline, { value: boolean }> {}

export type ToggleSidebarAction = Action<ActionType.toggleSidebar>;

export interface NavigateControllerPayload {
  navigateController: INavigateController;
}
export interface SetNavigateControllerAction
  extends ActionPayload<ActionType.setNavigateController, NavigateControllerPayload> {}

export interface SetSyncRequiredAction
  extends ActionPayload<ActionType.setSyncRequired, { value: boolean }> {}

export interface SetSyncFailedAction
  extends ActionPayload<ActionType.setSyncFailed, { value: boolean }> {}

export interface SetAlertPayload {
  alert?: IAlertMessage;
}

export interface SetAlertAction extends ActionPayload<ActionType.setAlert, SetAlertPayload> {}

export interface ConsumeAlertAction extends Action<ActionType.consumeAlert> {}

export interface SetSnackbarAction extends ActionPayload<ActionType.setSnackbar, SetAlertPayload> {}

export interface ConsumeSnackbarAction extends Action<ActionType.consumeSnackbar> {}

export type Actions =
  | SetIsAppOnlineAction
  | ToggleSidebarAction
  | SetSyncRequiredAction
  | SetSyncFailedAction
  | SetAlertAction
  | ConsumeAlertAction
  | SetSnackbarAction
  | ConsumeSnackbarAction
  | SetNavigateControllerAction;

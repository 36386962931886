import { Action } from "@reduxjs/toolkit";
import { IShiftSchedules, IScheduleItem } from "../../../types";
import { ActionPayload } from "../../actions";
import { RemoteResource } from "../../utils";

export interface ScheduleState {
  schedules: RemoteResource<IShiftSchedules>;
  currentSchedule: IScheduleItem[];
  loading: boolean;
}

export enum ActionType {
  getSchedulesRequested = "admin/get-schedules-requested",
  getSchedulesDone = "admin/get-schedules-done",
  getSchedulesError = "admin/get-schedules-error",
  setSchedulesRequested = "admin/set-schedules-requested",
  setSchedulesDone = "admin/set-schedules-done",
  setSchedulesError = "admin/set-schedules-error",
  setCurrentSchedule = "admin/set-current-schedules",
  clearData = "admin/clear-data",
}

/** GET SCHEDULES */

export interface GetSchedulesPayload {
  schedules: IShiftSchedules;
}

export interface GetSchedulesRequestedAction extends Action<ActionType.getSchedulesRequested> {}

export interface GetSchedulesDoneAction
  extends ActionPayload<ActionType.getSchedulesDone, GetSchedulesPayload> {}

export interface GetSchedulesErrorAction extends Action<ActionType.getSchedulesError> {}

/** SET SCHEDULES */

export interface SetSchedulesPayload {
  schedules: IShiftSchedules;
}
export interface SetSchedulesRequestedAction
  extends ActionPayload<ActionType.setSchedulesRequested, SetSchedulesPayload> {}

export interface SetSchedulesDoneAction
  extends ActionPayload<ActionType.setSchedulesDone, SetSchedulesPayload> {}

export interface SetSchedulesErrorAction extends Action<ActionType.setSchedulesError> {}

export interface SetCurrentScheduleAction
  extends ActionPayload<ActionType.setCurrentSchedule, { schedule: IScheduleItem[] }> {}

/** CLEAR DATA */

export interface GetClearDataAction extends Action<ActionType.clearData> {}

/** ACTION TYPES */

export type Actions =
  | GetSchedulesRequestedAction
  | GetSchedulesDoneAction
  | GetSchedulesErrorAction
  | SetSchedulesRequestedAction
  | SetSchedulesDoneAction
  | SetSchedulesErrorAction
  | SetCurrentScheduleAction
  | GetClearDataAction;

import { amber, blue, brown, green, grey, lightBlue, purple, red } from "@mui/material/colors";
import { ActivityType, IActivityItem, IAreaChecklist, IHourlyReviewItem } from "../types";
import { secondary_main } from "../theme/theme";
import AppStorage from "../services/AppStorage";

export const LANGUAGES = [
  { language: "es", label: "Español" },
  { language: "en", label: "English" },
  { language: "pt", label: "Português" },
  { language: "fr", label: "Français" },
];

export const GOAL_DEPARTMENTS = ["1", "2", "3"];
export const GOAL_DEPARTMENTS_DESCRIPTION: { [key: string]: string[] } = {
  "1": ["A", "B"],
  "2": ["A", "B"],
  "3": ["A", "B"],
};

export const WEEK_DAYS: string[] = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const DAY_OF_WEEK: { [key: number]: string } = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
};

export const AM_RUSH_HOURS: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const PM_RUSH_HOURS: number[] = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0];

export const TIME_LABELS: { [key: string]: number } = {
  "0:00": 0,
  "1:00": 1,
  "2:00": 2,
  "3:00": 3,
  "4:00": 4,
  "5:00": 5,
  "6:00": 6,
  "7:00": 7,
  "8:00": 8,
  "9:00": 9,
  "10:00": 10,
  "11:00": 11,
  "12:00": 12,
  "13:00": 13,
  "14:00": 14,
  "15:00": 15,
  "16:00": 16,
  "17:00": 17,
  "18:00": 18,
  "19:00": 19,
  "20:00": 20,
  "21:00": 21,
  "22:00": 22,
  "23:00": 23,
};

export const TIME_RANGES = ["afterRange", "beforeRange", "outOfRange"];

export const ACTIVIY_TYPES_ROUTE: { [key: string]: ActivityType } = {
  "/opening": ActivityType.OPENING,
  "/closing": ActivityType.CLOSING,
  "/pre-shift": ActivityType.PRE_SHIFT,
  "/objectives": ActivityType.OBJETIVES,
  "/checklist": ActivityType.CHECKLIST,
  "/hourly-review": ActivityType.SHIFT_TOUR,
  "/pos-shift": ActivityType.POS_SHIFT,
  "/notes-summary": ActivityType.NOTES,
  "/my-activities": ActivityType.CUSTOM,
};

export const SEGMENTS_ID = [
  "TOTALS",
  "DELIVERY",
  "DRIVE THRU",
  "FRONT COUNTER",
  "MOP",
  "MCCAFE",
  "COLD KIOSK",
  "CSO",
];

export const SERIES_COLORS: { [key: string]: string } = {
  DELIVERY: blue[600],
  "DRIVE THRU": red[900],
  "FRONT COUNTER": green[600],
  MOP: purple[700],
  MCCAFE: brown[500],
  "COLD KIOSK": lightBlue[300],
  CSO: amber[500],
  TOTALS: secondary_main,
  OTHERS: grey[600],
};

export const SYNCHRONIZABLE_ACTIVITIES: ActivityType[] = [
  ActivityType.OPENING,
  ActivityType.CLOSING,
  ActivityType.PRE_SHIFT,
  ActivityType.POS_SHIFT,
  ActivityType.CHECKLIST,
  ActivityType.SHIFT_TOUR,
  ActivityType.CUSTOM,
];

export const GET_SYNC_ACTIVITIES: {
  [key: string]: () => IAreaChecklist | IActivityItem[] | IHourlyReviewItem[] | null;
} = {
  OPN: AppStorage.getOpening,
  CLO: AppStorage.getClosing,
  PRE: AppStorage.getPreShift,
  POS: AppStorage.getPosShift,
  CHK: AppStorage.getChecklist,
  REV: AppStorage.getHourlyReviews,
  CUS: AppStorage.getMyActivities,
};

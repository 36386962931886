import { takeLatest, call, put, select } from "redux-saga/effects";

import { PromiseResult, getQueryParams } from "../../helpers/utils";
import { AlertCode, IHttpRequestError, IQueryParams } from "../../types";
import {
  ActionType,
  GetDailyTotalsRequestedAction,
  UpdateSalesHoursRequestedAction,
} from "./types";
import { setSnackbar } from "../app/actions";
import SalesService, { GetSalesResponse } from "../../services/SalesService";
import { filtersSelector } from "../admin/masterdata/selectors";
import {
  getDailyTotalsDone,
  getDailyTotalsError,
  updateSalesHoursDone,
  updateSalesHoursError,
} from "./actions";
import AppStorage from "../../services/AppStorage";

function* getDailyTotalsSagas(action: GetDailyTotalsRequestedAction) {
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
  };
  try {
    const {
      result: getDailyTotalsResponse,
      hasError,
      error,
    }: PromiseResult<GetSalesResponse> = yield call(SalesService.getDailyTotals, params);
    if (hasError || !getDailyTotalsResponse) throw error;
    const dailyTotals = getDailyTotalsResponse.data;
    yield put(getDailyTotalsDone({ dailyTotals }));
    AppStorage.setSalesRequestDate(new Date().toISOString());
  } catch (error) {
    yield put(getDailyTotalsError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.SALES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* updateSalesHoursSagas(action: UpdateSalesHoursRequestedAction) {
  const params: IQueryParams = {
    ...getQueryParams(yield select(filtersSelector)),
  };
  try {
    const {
      result: updateSalesHoursResponse,
      hasError,
      error,
    }: PromiseResult<GetSalesResponse> = yield call(
      SalesService.updateSalesHours,
      params,
      action.payload.data
    );
    if (hasError || !updateSalesHoursResponse) throw error;
    yield put(updateSalesHoursDone({ data: action.payload.data }));
  } catch (error) {
    yield put(updateSalesHoursError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.SALES_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

const salesSagas = [
  takeLatest(ActionType.getDailyTotalsRequested, getDailyTotalsSagas),
  takeLatest(ActionType.updateSalesHoursRequested, updateSalesHoursSagas),
];

export default salesSagas;

import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { ActionButton, FlexBox, SecondaryText } from "../styles";
import { IHourlyReviewState, IHourlyReviewStepsList } from "../types";
import { HourlyReviewDescription, getHourlyReviewStepDescription } from "../helpers/utils";
import styled from "@emotion/styled";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  StepConnector,
  SxProps,
  stepConnectorClasses,
} from "@mui/material";
import ItemIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";

const titleStyle: SxProps = {
  fontWeight: 500,
};

const SimpleLineConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    minHeight: "0px !important",
  },
}));

interface Props {
  steps: IHourlyReviewStepsList[];
  hourlyReviewState: IHourlyReviewState;
  loading: boolean;
  onComplete: () => void;
}

const TourStepper = ({ steps, hourlyReviewState, loading, onComplete }: Props) => {
  const { t } = useTranslation();
  const { completed, hour } = hourlyReviewState;
  const [activeStep, setActiveStep] = useState(completed ? steps.length : 0);
  const finalStep = steps.length - 1;

  useEffect(() => {
    if (completed) setActiveStep(steps.length);
    else if (!completed && activeStep === steps.length) setActiveStep(finalStep);
    else setActiveStep(0);
    return () => setActiveStep(completed ? steps.length : 0);
    // eslint-disable-next-line
  }, [hour, completed]);

  const handleNext = () => {
    if (activeStep + 1 === steps.length) onComplete();
    else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical" connector={<SimpleLineConnector />}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>
              <FlexBox>
                <Typography sx={titleStyle}>
                  {getHourlyReviewStepDescription(step, "group")}
                </Typography>
                <SecondaryText ml={1}>{`- ${getHourlyReviewStepDescription(
                  step,
                  "focus"
                )}`}</SecondaryText>
              </FlexBox>
            </StepLabel>
            <StepContent>
              <Typography>{getHourlyReviewStepDescription(step, "description")}</Typography>
              <List dense sx={{ py: 0 }}>
                {step.descriptions.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemIcon sx={{ minWidth: 20 }}>
                      <ItemIcon sx={{ width: 10, height: 10 }} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>{HourlyReviewDescription(item)}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
              <Box sx={{ mb: 2 }}>
                <div>
                  <ActionButton
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                    loading={loading}
                    disabled={loading}
                  >
                    {index === finalStep ? t("labels.finish") : t("labels.continue")}
                  </ActionButton>
                  <ActionButton disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    {t("labels.back")}
                  </ActionButton>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default TourStepper;

import React, { Suspense } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import "./App.css";
import { CircularProgress, PaletteMode, ThemeProvider } from "@mui/material";
import Router from "./router/Router";
import { getMuiTheme } from "./theme/theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import { isAuthenticatedSelector, preferencesSelector } from "./redux/user/selectors";
import InitialRequests from "./components/InitialRequests";
import { getLocale } from "./helpers/utils";

const App = () => {
  const { inProgress } = useMsal();
  const preferences = useSelector(preferencesSelector);
  const prefersDarkMode: PaletteMode = preferences?.paletteMode || "light";
  const theme = React.useMemo(() => getMuiTheme(prefersDarkMode), [prefersDarkMode]);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  if (inProgress === InteractionStatus.Startup || inProgress === InteractionStatus.HandleRedirect) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale()}>
        <Suspense fallback={<CircularProgress size={24} />}>
          <Router isAuthenticated={isAuthenticated} />
        </Suspense>
        <InitialRequests isAuthenticated={isAuthenticated} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;

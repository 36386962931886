import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper, SxProps } from "@mui/material";
import { intervalToDuration } from "date-fns";
import { numberFormatter } from "../helpers/utils";
import ProgressIcon from "@mui/icons-material/AlarmOn";
import { useDispatch, useSelector } from "react-redux";
import { setHourlyReviewState } from "../redux/activities/rev/actions";
import {
  hourlyReviewConfirmedSelector,
  hourlyReviewDataSelector,
} from "../redux/activities/rev/selectors";
import { useTranslation } from "react-i18next";
import { SecondaryText } from "../styles";
import { TimeRange } from "../pages/activities/HourlyReview";

const containerBox: SxProps = {
  cursor: "pointer",
  position: "relative",
  display: "grid",
  p: 1,
  px: 2,
  width: "100%",
  textAlign: "center",
  "&:hover": {
    opacity: 0.8,
  },
};

const completedContent: SxProps = {
  position: "relative",
  display: "grid",
  pt: 1,
  px: 2,
  width: "100%",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
};

const timerContainer: SxProps = {
  position: "relative",
  display: "inline-flex",
  pt: 1,
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
};

const timerContent: SxProps = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

function CustomCircularProgress(props: CircularProgressProps) {
  const severity = "secondary.main";
  // const severity = props.value && props.value < 25 ? "error.light" : "secondary.main";
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: "primary.main",
          opacity: 0.6,
        }}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: severity,
          animationDuration: "500ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        {...props}
      />
    </Box>
  );
}

interface Props {
  title?: string;
  description?: string;
  timeRange?: TimeRange;
  showHourRange?: boolean;
  size?: number;
  thickness?: number;
  onClick?: () => void;
}

const TimerProgress = ({
  title,
  description,
  timeRange,
  showHourRange,
  size,
  thickness,
  onClick,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hourlyReviewState = useSelector(hourlyReviewConfirmedSelector);
  const hourlyReviews = useSelector(hourlyReviewDataSelector);
  const { hour, completed } = hourlyReviewState;
  const [progress, setProgress] = React.useState(0);
  const [time, setTime] = React.useState("00:00");
  const [outOfRange, setOutOfRange] = React.useState(false);
  const iconSize = size ? size * 0.7 : 140;
  const textSize = size ? size * 0.014 : 3.5;

  React.useEffect(() => {
    getTime();
    const timer = setInterval(() => {
      getTime();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [hourlyReviewState, hourlyReviews]);

  React.useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour > hour)
      dispatch(
        setHourlyReviewState({
          value: { hour: currentHour, confirmed: false, completed: false },
        })
      );
    if (timeRange === "inRange") setOutOfRange(false);
    else setOutOfRange(true);
    // eslint-disable-next-line
  }, [progress, hour, timeRange]);

  const getTime = () => {
    const now = new Date();
    const startDate = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      now.getDate(),
      now.getHours(),
      0,
      0
    );
    const interval = intervalToDuration({ start: startDate, end: now });
    const minutes = numberFormatter(interval.minutes || 0, 2);
    const seconds = numberFormatter(interval.seconds || 0, 2);
    const percentage = ((interval.minutes || 0) * 100) / 60;
    setTime(`${minutes}:${seconds}`);
    setProgress(percentage > 0 ? percentage : 1);
  };

  return (
    <Box component={Paper} sx={containerBox} onClick={onClick}>
      <Typography variant="h5">{title}</Typography>
      {completed ? (
        <Box sx={completedContent}>
          <ProgressIcon sx={{ width: iconSize, height: iconSize }} color="success" />
          <Typography variant="caption">{t("labels.completed")}</Typography>
        </Box>
      ) : (
        <Box sx={timerContainer}>
          <CustomCircularProgress
            value={completed || outOfRange ? 0 : progress}
            size={size || 220}
            thickness={thickness || 2}
          />
          <Box sx={timerContent} mt={0.25}>
            <Typography variant="caption">{description}</Typography>
            <Typography variant="h3" sx={{ fontSize: textSize + "rem" }}>
              {outOfRange ? "00:00" : time}
            </Typography>
          </Box>
        </Box>
      )}
      {showHourRange && (
        <SecondaryText variant="h6">{`${hour}:00 - ${hour + 1}:00 hs.`}</SecondaryText>
      )}
    </Box>
  );
};

export default TimerProgress;

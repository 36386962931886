import { call, put, select, takeEvery } from "redux-saga/effects";
import { PromiseResult, areasChecklistRawFormatter, getQueryParams } from "../../../helpers/utils";
import ActivityServices from "../../../services/ActivityServices";
import {
  ActivityType,
  AlertCode,
  IActivitiesState,
  IAreaChecklist,
  IHttpRequestError,
  IManager,
  IQueryParams,
  ISyncState,
} from "../../../types";
import { setSnackbar, setSyncFailed, setSyncRequired } from "../../app/actions";
import {
  setAreasChecklistDone,
  setAreasChecklistError,
  setMyActivitiesDone,
  setMyActivitiesError,
  setPosShiftDone,
  setPosShiftError,
  setPreShiftDone,
  setPreShiftError,
} from "./actions";
import {
  ActionType,
  SetAreasChecklistRequestedAction,
  SetMyActivitiesRequestedAction,
  SetPosShiftRequestedAction,
  SetPreShiftRequestedAction,
} from "./types";
import AppStorage from "../../../services/AppStorage";
import { filtersSelector } from "../../admin/masterdata/selectors";
import { appUserSelector } from "../../user/selectors";
import { checklistsStateSelector } from "./selectors";
import { isAppOnlineSelector, syncRequiredSelector } from "../../app/selectors";
import { ApiResponse, MyActivitiesResponse } from "../../../services/interfaces";
import { storePlanIdSelector } from "../../storePlan/selectors";

function* setPreShiftSagas(action: SetPreShiftRequestedAction) {
  const isAppOnline: boolean = yield select(isAppOnlineSelector);
  const syncRequired: ISyncState = yield select(syncRequiredSelector);
  const user: IManager = yield select(appUserSelector);
  const { shiftTypeId } = getQueryParams(yield select(filtersSelector));
  const params: IQueryParams = {
    username: user.username,
    shiftTypeId,
  };
  try {
    const data = action.payload.data;
    if (isAppOnline) {
      const {
        result: response,
        hasError,
        error,
      }: PromiseResult<ApiResponse> = yield call(ActivityServices.setCheckList, data, params);
      if (hasError || !response) throw error;
      AppStorage.clearPreShift();
    } else {
      yield put(setSyncRequired(true));
      AppStorage.setPreShift(data);
    }
    yield put(setPreShiftDone({ data }));
  } catch (error) {
    yield put(setPreShiftError());
    yield put(
      setSnackbar({
        alert: { code: AlertCode.PRE_ERROR, error: error as IHttpRequestError, severity: "error" },
      })
    );
    if (syncRequired) yield put(setSyncFailed(true));
  }
}

function* setPosShiftSagas(action: SetPosShiftRequestedAction) {
  const isAppOnline: boolean = yield select(isAppOnlineSelector);
  const syncRequired: ISyncState = yield select(syncRequiredSelector);
  const user: IManager = yield select(appUserSelector);
  const { shiftTypeId } = getQueryParams(yield select(filtersSelector));
  const params: IQueryParams = {
    username: user.username,
    shiftTypeId,
  };
  try {
    const data = action.payload.data;
    if (isAppOnline) {
      const {
        result: response,
        hasError,
        error,
      }: PromiseResult<ApiResponse> = yield call(ActivityServices.setCheckList, data, params);
      if (hasError || !response) throw error;
      AppStorage.clearPosShift();
    } else {
      yield put(setSyncRequired(true));
      AppStorage.setPosShift(data);
    }
    yield put(setPosShiftDone({ data }));
  } catch (error) {
    yield put(setPosShiftError());
    yield put(
      setSnackbar({
        alert: { code: AlertCode.POS_ERROR, error: error as IHttpRequestError, severity: "error" },
      })
    );
    if (syncRequired) yield put(setSyncFailed(true));
  }
}

function* setAreasChecklistSagas(action: SetAreasChecklistRequestedAction) {
  const isAppOnline: boolean = yield select(isAppOnlineSelector);
  const syncRequired: ISyncState = yield select(syncRequiredSelector);
  const activitiesState: IActivitiesState = yield select(checklistsStateSelector);
  const activityTypesId: ActivityType = action.payload.activityTypesId as ActivityType;
  const user: IManager = yield select(appUserSelector);
  const { shiftTypeId } = getQueryParams(yield select(filtersSelector));
  const params: IQueryParams = {
    username: user.username,
    shiftTypeId,
  };
  try {
    const data = action.payload.data;
    let changedData: IAreaChecklist = {};
    action.payload.areas?.forEach((key) => (changedData[key] = data[key]));
    const formattedData = activitiesState.syncError[activityTypesId]
      ? areasChecklistRawFormatter(data)
      : areasChecklistRawFormatter(changedData);
    if (isAppOnline) {
      const {
        result: response,
        hasError,
        error,
      }: PromiseResult<ApiResponse> = yield call(
        ActivityServices.setCheckList,
        formattedData,
        params
      );
      if (hasError || !response) throw error;
      switch (activityTypesId) {
        case ActivityType.OPENING:
          AppStorage.clearOpening();
          break;
        case ActivityType.CLOSING:
          AppStorage.clearClosing();
          break;
        case ActivityType.CHECKLIST:
          AppStorage.clearChecklist();
          break;
        default:
          break;
      }
    } else {
      yield put(setSyncRequired(true));
      switch (activityTypesId) {
        case ActivityType.OPENING:
          AppStorage.setOpening(data);
          break;
        case ActivityType.CLOSING:
          AppStorage.setClosing(data);
          break;
        case ActivityType.CHECKLIST:
          AppStorage.setChecklist(data);
          break;
        default:
          break;
      }
    }
    yield put(setAreasChecklistDone({ data, activityTypesId }));
  } catch (error) {
    yield put(setAreasChecklistError(activityTypesId));
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.CHECKLIST_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
    if (syncRequired) yield put(setSyncFailed(true));
  }
}

function* setMyActivitiesSagas(action: SetMyActivitiesRequestedAction) {
  const isAppOnline: boolean = yield select(isAppOnlineSelector);
  const syncRequired: ISyncState = yield select(syncRequiredSelector);
  const user: IManager = yield select(appUserSelector);
  const storePlanId: number = yield select(storePlanIdSelector);
  const { shiftTypeId } = getQueryParams(yield select(filtersSelector));

  const params: IQueryParams = {
    username: user.username,
    shiftTypeId,
    storePlanId,
  };
  try {
    const data = action.payload.data;
    if (isAppOnline) {
      const {
        result: setActivitiesResponse,
        hasError,
        error,
      }: PromiseResult<MyActivitiesResponse> = yield call(
        ActivityServices.setMyActivities,
        data,
        params
      );
      if (hasError || !setActivitiesResponse) throw error;
      yield put(setMyActivitiesDone({ data: setActivitiesResponse.data.data }));
      AppStorage.clearMyActivities();
    } else {
      yield put(setSyncRequired(true));
      yield put(setMyActivitiesDone({ data }));
      AppStorage.setMyActivities(data);
    }
  } catch (error) {
    yield put(setMyActivitiesError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.CHECKLIST_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
    if (syncRequired) yield put(setSyncFailed(true));
  }
}

const activitySagas = [
  takeEvery(ActionType.setPreShiftRequested, setPreShiftSagas),
  takeEvery(ActionType.setPosShiftRequested, setPosShiftSagas),
  takeEvery(ActionType.setAreasChecklistRequested, setAreasChecklistSagas),
  takeEvery(ActionType.setMyActivitiesRequested, setMyActivitiesSagas),
];

export default activitySagas;

import { IUserPreferences, IActivityItem, IAreaChecklist, IHourlyReviewItem } from "../types";

const USER_PREFERENCES_KEY = "miturno-preferences";
const SALES_REQUEST_DATE_KEY = "miturno-sales-request-date";

const PRESHIFT_KEY = "miturno-preshift";
const POSTSHIFT_KEY = "miturno-posshift";
const OPENING_KEY = "miturno-opening";
const CLOSING_KEY = "miturno-closing";
const CHECKLIST_KEY = "miturno-checklist";
const MY_ACTIVITIES_KEY = "miturno-my-activities";
const HOURLY_REVIEWS_KEY = "miturno-hourly-reviews";

class AppStorage {
  /** APP & USER */

  static getUserPreferences(): IUserPreferences | null {
    return JSON.parse(localStorage.getItem(USER_PREFERENCES_KEY) || "null");
  }

  static setUserPreferences(preferences: IUserPreferences) {
    localStorage.setItem(USER_PREFERENCES_KEY, JSON.stringify(preferences));
  }

  /** ACTIVITIES */

  static clearActivities(): void {
    localStorage.removeItem(OPENING_KEY);
    localStorage.removeItem(CLOSING_KEY);
    localStorage.removeItem(CHECKLIST_KEY);
    localStorage.removeItem(PRESHIFT_KEY);
    localStorage.removeItem(POSTSHIFT_KEY);
    localStorage.removeItem(HOURLY_REVIEWS_KEY);
    localStorage.removeItem(MY_ACTIVITIES_KEY);
  }

  static getOpening(): IAreaChecklist | null {
    return JSON.parse(localStorage.getItem(OPENING_KEY) || "null");
  }

  static setOpening(areaChecklist: IAreaChecklist) {
    localStorage.setItem(OPENING_KEY, JSON.stringify(areaChecklist));
  }

  static clearOpening(): void {
    localStorage.removeItem(OPENING_KEY);
  }

  static getClosing(): IAreaChecklist | null {
    return JSON.parse(localStorage.getItem(CLOSING_KEY) || "null");
  }

  static setClosing(areaChecklist: IAreaChecklist) {
    localStorage.setItem(CLOSING_KEY, JSON.stringify(areaChecklist));
  }

  static clearClosing(): void {
    localStorage.removeItem(CLOSING_KEY);
  }

  static getPreShift(): IActivityItem[] | null {
    return JSON.parse(localStorage.getItem(PRESHIFT_KEY) || "null");
  }

  static setPreShift(preShift: IActivityItem[]) {
    localStorage.setItem(PRESHIFT_KEY, JSON.stringify(preShift));
  }

  static clearPreShift(): void {
    localStorage.removeItem(PRESHIFT_KEY);
  }

  static getPosShift(): IActivityItem[] | null {
    return JSON.parse(localStorage.getItem(POSTSHIFT_KEY) || "null");
  }

  static setPosShift(posShift: IActivityItem[]) {
    localStorage.setItem(POSTSHIFT_KEY, JSON.stringify(posShift));
  }

  static clearPosShift(): void {
    localStorage.removeItem(POSTSHIFT_KEY);
  }

  static getChecklist(): IAreaChecklist | null {
    return JSON.parse(localStorage.getItem(CHECKLIST_KEY) || "null");
  }

  static setChecklist(checklist: IAreaChecklist) {
    localStorage.setItem(CHECKLIST_KEY, JSON.stringify(checklist));
  }

  static clearChecklist() {
    localStorage.removeItem(CHECKLIST_KEY);
  }

  static getMyActivities(): IActivityItem[] | null {
    return JSON.parse(localStorage.getItem(MY_ACTIVITIES_KEY) || "null");
  }

  static setMyActivities(myActivities: IActivityItem[]) {
    localStorage.setItem(MY_ACTIVITIES_KEY, JSON.stringify(myActivities));
  }

  static clearMyActivities() {
    localStorage.removeItem(MY_ACTIVITIES_KEY);
  }

  static getHourlyReviews(): IHourlyReviewItem[] | null {
    return JSON.parse(localStorage.getItem(HOURLY_REVIEWS_KEY) || "null");
  }

  static setHourlyReviews(item: IHourlyReviewItem) {
    let newHourlyReviews: IHourlyReviewItem[] = [];
    const hourlyReviews: IHourlyReviewItem[] | null = JSON.parse(
      localStorage.getItem(HOURLY_REVIEWS_KEY) || "null"
    );

    if (hourlyReviews && hourlyReviews.length > 0) {
      const index = hourlyReviews.findIndex((i) => i.id === item.id);
      newHourlyReviews = [...hourlyReviews];
      if (index !== -1) newHourlyReviews[index] = { ...item };
      else newHourlyReviews.push(item);
    } else {
      newHourlyReviews.push(item);
    }
    localStorage.setItem(HOURLY_REVIEWS_KEY, JSON.stringify(newHourlyReviews));
  }

  static clearHourlyReviews() {
    localStorage.removeItem(HOURLY_REVIEWS_KEY);
  }

  /** SALES */

  static getSalesRequestDate(): string | null {
    return JSON.parse(localStorage.getItem(SALES_REQUEST_DATE_KEY) || "null");
  }

  static setSalesRequestDate(date: string) {
    localStorage.setItem(SALES_REQUEST_DATE_KEY, JSON.stringify(date));
  }
}

export default AppStorage;

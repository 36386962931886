import { takeLatest, call, put, select } from "redux-saga/effects";
import StorePlanServices, {
  CustomActivitiesResponse,
  CustomChecklistsResponse,
} from "../../../services/StorePlanServices";
import { PromiseResult, getQueryParams } from "../../../helpers/utils";
import { AlertCode, IHttpRequestError } from "../../../types";
import {
  getChecklistsDone,
  getChecklistsError,
  addChecklistDone,
  addChecklistError,
  deleteChecklistDone,
  deleteChecklistError,
  updateChecklistDone,
  updateChecklistError,
  addActivitiesDone,
  addActivitiesError,
  deleteActivitiesError,
  deleteActivitiesDone,
} from "./actions";
import {
  ActionType,
  GetChecklistsRequestedAction,
  AddChecklistRequestedAction,
  DeleteChecklistRequestedAction,
  UpdateChecklistRequestedAction,
  AddActivitiesRequestedAction,
  DeleteActivitiesRequestedAction,
} from "./types";
import { setSnackbar } from "../../app/actions";
import { ApiResponse, GetCustomChecklistsResponse } from "../../../services/interfaces";
import { filtersSelector } from "../masterdata/selectors";

function* getChecklistsSagas(action: GetChecklistsRequestedAction) {
  const params = getQueryParams(yield select(filtersSelector));
  try {
    const {
      result: getChecklistsResponse,
      hasError,
      error,
    }: PromiseResult<GetCustomChecklistsResponse> = yield call(
      StorePlanServices.getCustomChecklists,
      params
    );
    if (hasError || !getChecklistsResponse) throw error;
    const data = getChecklistsResponse.data.data;
    yield put(getChecklistsDone({ checklists: data }));
  } catch (error) {
    yield put(getChecklistsError());
  }
}

function* addChecklistSagas(action: AddChecklistRequestedAction) {
  const params = getQueryParams(yield select(filtersSelector));
  try {
    const {
      result: addChecklistResponse,
      hasError,
      error,
    }: PromiseResult<CustomChecklistsResponse> = yield call(
      StorePlanServices.addCustomChecklist,
      action.payload.checklist,
      params
    );
    if (hasError || !addChecklistResponse) throw error;
    const data = addChecklistResponse.data.data;
    yield put(addChecklistDone({ checklist: data }));
  } catch (error) {
    yield put(addChecklistError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.CHECKLIST_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* updateChecklistSagas(action: UpdateChecklistRequestedAction) {
  const params = getQueryParams(yield select(filtersSelector));
  try {
    if (!action.payload.ignoreFetch) {
      const {
        result: updateChecklistResponse,
        hasError,
        error,
      }: PromiseResult<CustomChecklistsResponse> = yield call(
        StorePlanServices.updateCustomChecklist,
        action.payload.checklist,
        params
      );
      if (hasError || !updateChecklistResponse) throw error;
      yield put(updateChecklistDone({ checklist: updateChecklistResponse.data.data }));
    }
    // yield put(updateChecklistDone({ checklist: action.payload.checklist })); /** ignora response del BE */
  } catch (error) {
    yield put(updateChecklistError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.CHECKLIST_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* deleteChecklistSagas(action: DeleteChecklistRequestedAction) {
  try {
    const {
      result: deleteChecklistResponse,
      hasError,
      error,
    }: PromiseResult<ApiResponse> = yield call(
      StorePlanServices.deleteCustomChecklist,
      action.payload.id
    );
    if (hasError || !deleteChecklistResponse) throw error;
    yield put(deleteChecklistDone({ id: action.payload.id }));
  } catch (error) {
    yield put(deleteChecklistError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.CHECKLIST_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* addActivitiesSagas(action: AddActivitiesRequestedAction) {
  const customChecklistId = action.payload.customChecklistId;
  try {
    const {
      result: addActivitiesResponse,
      hasError,
      error,
    }: PromiseResult<CustomActivitiesResponse> = yield call(
      StorePlanServices.addCustomActivities,
      action.payload.activities,
      customChecklistId
    );
    if (hasError || !addActivitiesResponse) throw error;
    const data = addActivitiesResponse.data.data;
    yield put(addActivitiesDone({ activities: data }));
  } catch (error) {
    yield put(addActivitiesError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.CHECKLIST_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

function* deleteActivitiesSagas(action: DeleteActivitiesRequestedAction) {
  try {
    const {
      result: deleteActivitiesResponse,
      hasError,
      error,
    }: PromiseResult<ApiResponse> = yield call(
      StorePlanServices.deleteCustomActivities,
      action.payload.ids
    );
    if (hasError || !deleteActivitiesResponse) throw error;
    yield put(
      deleteActivitiesDone({
        ids: action.payload.ids,
        customChecklistId: action.payload.customChecklistId,
      })
    );
  } catch (error) {
    yield put(deleteActivitiesError());
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.CHECKLIST_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
  }
}

const activitiesSagas = [
  takeLatest(ActionType.getChecklistsRequested, getChecklistsSagas),
  takeLatest(ActionType.addChecklistRequested, addChecklistSagas),
  takeLatest(ActionType.updateChecklistRequested, updateChecklistSagas),
  takeLatest(ActionType.deleteChecklistRequested, deleteChecklistSagas),
  takeLatest(ActionType.addActivitiesRequested, addActivitiesSagas),
  takeLatest(ActionType.deleteActivitiesRequested, deleteActivitiesSagas),
];

export default activitiesSagas;

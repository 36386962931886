import React from "react";
import { Checkbox, FormControlLabel, SxProps } from "@mui/material";
import { IActivityItem } from "../types";
import { getCheckListDescription } from "../helpers/utils";

interface Props {
  item: IActivityItem;
  active: boolean;
  isCustom?: boolean;
  handler: (item: IActivityItem, value: boolean) => void;
}

const activeCheckedStyle: SxProps = {
  opacity: 0,
  transition: "opacity 500ms",
  textDecoration: "line-through",
};

const defaultActiveCheckedStyle: SxProps = {
  opacity: 1,
  transition: "opacity 500ms",
};

const inactiveCheckedStyle: SxProps = {
  opacity: 0.75,
  transition: "opacity 500ms",
  textDecoration: "line-through",
};

const defaultInactiveCheckedStyle: SxProps = {
  opacity: 0,
  transition: "opacity 500ms",
  textDecoration: "line-through",
};

const CheckItem = ({ item, active, isCustom, handler }: Props) => {
  const [checked, setChecked] = React.useState(active);

  React.useEffect(() => {
    setChecked(item.checked);
  }, [item]);

  const handleChange = () => {
    const newValue = !checked;
    const newItem = { ...item, checked: newValue };
    setChecked(newValue);
    setTimeout(() => {
      handler(newItem, newValue);
    }, 500);
  };

  const getStyle = (): SxProps => {
    if (active) {
      return checked ? activeCheckedStyle : defaultActiveCheckedStyle;
    } else {
      return checked ? inactiveCheckedStyle : defaultInactiveCheckedStyle;
    }
  };

  return (
    <FormControlLabel
      control={<Checkbox onChange={handleChange} checked={checked} />}
      label={getCheckListDescription(item, isCustom)}
      sx={getStyle()}
    />
  );
};

export default CheckItem;

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import appReducer from "./app/reducer";
import userReducer from "./user/reducer";

import masterdataReducer from "./admin/masterdata/reducer";
import masterdataSagas from "./admin/masterdata/sagas";
import areasReducer from "./admin/areas/reducer";
import areasSagas from "./admin/areas/sagas";
import managerReducer from "./admin/managers/reducer";
import managersSagas from "./admin/managers/sagas";
import schedulesReducer from "./admin/schedules/reducer";
import schedulesSagas from "./admin/schedules/sagas";

import activitiesReducer from "./admin/activities/reducer";
import activitiesSagas from "./admin/activities/sagas";

import userSagas from "./user/sagas";
import salesSagas from "./sales/sagas";
import salesReducer from "./sales/reducer";
import storePlanSagas from "./storePlan/sagas";
import storePlanReducer from "./storePlan/reducer";
import checklistReducer from "./activities/checklists/reducer";
import checklistSagas from "./activities/checklists/sagas";
import revReducer from "./activities/rev/reducer";
import revSagas from "./activities/rev/sagas";
import notesReducer from "./activities/notes/reducer";
import notesSagas from "./activities/notes/sagas";
import objectivesReducer from "./activities/objectives/reducer";
import objectivesSagas from "./activities/objectives/sagas";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    masterdata: masterdataReducer,
    areas: areasReducer,
    managers: managerReducer,
    schedules: schedulesReducer,
    activities: activitiesReducer,
    sales: salesReducer,
    storePlan: storePlanReducer,
    checklists: checklistReducer,
    notes: notesReducer,
    objectives: objectivesReducer,
    rev: revReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(function* () {
  yield all([
    ...masterdataSagas,
    ...areasSagas,
    ...managersSagas,
    ...schedulesSagas,
    ...activitiesSagas,
    ...userSagas,
    ...storePlanSagas,
    ...checklistSagas,
    ...revSagas,
    ...notesSagas,
    ...objectivesSagas,
    ...salesSagas,
  ]);
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import ItemIcon from "@mui/icons-material/Circle";
import { ActionButton, FlexBox } from "../styles";
import { IHRConfirmDescriptions } from "../types";
import { HourlyReviewDescription } from "../helpers/utils";
import ConfirmIcon from "@mui/icons-material/Report";

interface Props {
  descriptions?: IHRConfirmDescriptions;
  disabled?: boolean;
  onConfirm: () => void;
}

export const HourlyReviewConfirm = ({ descriptions, disabled, onConfirm }: Props) => {
  const { t } = useTranslation();

  return (
    <Box component={Paper} p={2}>
      <FlexBox mb={2}>
        <ConfirmIcon color="error" sx={{ mr: 1, width: 30, height: 30 }} />
        <Typography variant="h5">{t("pages.hourlyReview.hourTour")}</Typography>
      </FlexBox>
      <Typography>{HourlyReviewDescription(descriptions?.title)}</Typography>
      <List dense sx={{ ml: 1 }}>
        {descriptions?.descriptions?.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon sx={{ minWidth: 20 }}>
              <ItemIcon sx={{ width: 10, height: 10 }} />
            </ListItemIcon>
            <ListItemText>
              <Typography>{HourlyReviewDescription(item)}</Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
      <ActionButton
        variant="contained"
        onClick={onConfirm}
        disabled={disabled}
        sx={{ px: 5, mt: 2 }}
      >
        {t("labels.continue")}
      </ActionButton>
    </Box>
  );
};

export default HourlyReviewConfirm;

import { Container, Grid, SxProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { ActionButton, SecondaryText } from "../../styles";
import MaintenanceIcon from "@mui/icons-material/Settings"; // Settings Construction Engineering
import { useNavigate } from "react-router-dom";
import { routes } from "../../router/routes";

const iconFx: SxProps = {
  height: 200,
  width: 200,
  "@keyframes glowFx": {
    from: { opacity: 1, transform: "scale(1)" },
    to: { opacity: 0.9, transform: "scale(1.08)" },
  },
  animation: "glowFx 1s ease-in-out infinite alternate",
};

const MaintenanceScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container maxWidth={false}>
      <Grid container spacing={1} p={4} justifyContent={"center"} textAlign={"center"}>
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ my: 2 }}>
            {t("errors.codes.siteOnMaintenance.title")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MaintenanceIcon color="primary" sx={iconFx} />
        </Grid>
        <Grid item xs={12}>
          <SecondaryText variant="h5">
            {t("errors.codes.siteOnMaintenance.description")}
          </SecondaryText>
          <SecondaryText variant="h5">
            {t("errors.codes.siteOnMaintenance.description2")}
          </SecondaryText>
        </Grid>
        <Grid item xs={12} mt={4}>
          <ActionButton
            variant="contained"
            size="medium"
            sx={{ mx: 1, px: 4 }}
            onClick={() => (window.location.href = process.env.REACT_APP_LAUNCHER_URL || "")}
          >
            {t("labels.backToFlex")}
          </ActionButton>
          <ActionButton
            variant="outlined"
            size="medium"
            sx={{ mx: 1, px: 4 }}
            onClick={() => window.location.replace(routes.login.path)}
          >
            {t("labels.reload")}
          </ActionButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MaintenanceScreen;

import { ScheduleState } from "./types";

interface State {
  schedules: ScheduleState;
}

export const schedulesSelector = (state: State) => state.schedules.schedules;
export const schedulesDataSelector = (state: State) => state.schedules.schedules.data;
export const currentScheduleSelector = (state: State) => state.schedules.currentSchedule;
export const loadingSelector = (state: State) => state.schedules.loading;

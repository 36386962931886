import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { IObjectiveItem, IObjectivesTypes } from "../../types";
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ActionButton, FlexBox, SecondaryText } from "../../styles";
import { getObjectiveTypeDescription } from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import ErrorIcon from "@mui/icons-material/Error";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const titleStyle: SxProps = {
  textAlign: "center",
  color: "primary",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

interface Props {
  objectivesList: IObjectiveItem[];
  objectiveTypes: IObjectivesTypes;
  data?: IObjectiveItem;
  open: boolean;
  onSubmit: (newData?: Partial<IObjectiveItem>, isDeleting?: boolean) => void;
}

export const ObjectiveFormModal = ({ objectivesList, objectiveTypes, data, open, onSubmit }: Props) => {
  const { t } = useTranslation();
  const [selectedObjType, setSelectedObjType] = React.useState("");
  const [selectedObjItem, setSelectedObjItem] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [completed, setCompleted] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const isEdit = Boolean(data);
  const diasableSubmit =
    Boolean(error) ||
    (selectedObjType === "OT" && description === "") ||
    (selectedObjType !== "OT" && selectedObjItem === "") ||
    selectedObjType === "" ||
    description === "";

  React.useEffect(() => {
    if (data) {
      setSelectedObjType(data.objectiveTypeId);
      setSelectedObjItem(data.objectiveItemId);
      setDescription(data.objectiveDescription);
    }
  }, [data]);

  const handleSubmit = () => {
    if (checkDuplicate()) return null;
    const objType = objectiveTypes.objectiveType.find((o) => o.id === selectedObjType);
    const objItem = objectiveTypes.objectiveItem.find((o) => o.id === selectedObjItem);
    if (selectedObjType === "OT") {
      const newObjective: Partial<IObjectiveItem> = {
        objectiveTypeId: objType?.id,
        objectiveItemId: "OT1",
        objectiveDescription: description,
        objectiveAchieved: false,
        objectiveTypeDescription_es: objType?.description_es,
        objectiveTypeDescription_en: objType?.description_en,
        objectiveTypeDescription_fr: objType?.description_fr,
        objectiveTypeDescription_pt: objType?.description_pt,
      };
      onSubmit(newObjective);
    } else if (objType && objItem) {
      const newObjective: Partial<IObjectiveItem> = {
        objectiveTypeId: objType.id,
        objectiveItemId: objItem.id,
        objectiveDescription: description,
        objectiveAchieved: false,
        objectiveTypeDescription_es: objType.description_es,
        objectiveTypeDescription_en: objType.description_en,
        objectiveTypeDescription_fr: objType.description_fr,
        objectiveTypeDescription_pt: objType.description_pt,
        objectiveItemDescription_es: objItem.description_es,
        objectiveItemDescription_en: objItem.description_en,
        objectiveItemDescription_fr: objItem.description_fr,
        objectiveItemDescription_pt: objItem.description_pt,
      };
      onSubmit(newObjective);
    }
    handleReset();
  };

  const handleClose = () => {
    if (isDeleting) {
      setIsDeleting(false);
    } else {
      onSubmit();
    }
    handleReset();
  };

  const checkDuplicate = () => {
    setError(undefined);
    let result = false;
    objectivesList.forEach((item) => {
      const { objectiveTypeId, objectiveItemId, objectiveDescription } = item;
      if (
        (selectedObjType === "OT" &&
          selectedObjType === objectiveTypeId &&
          description === objectiveDescription) ||
        (selectedObjType === objectiveTypeId &&
          selectedObjItem === objectiveItemId &&
          description === objectiveDescription)
      ) {
        result = true;
        setError(t("errors.objectives.duplicateObjective").toString());
        return;
      }
    });
    return result;
  };

  const handleReset = () => {
    setSelectedObjType("");
    setSelectedObjItem("");
    setDescription("");
    setError(undefined);
  };

  const renderContent = () => {
    return (
      <>
        <DialogTitle sx={titleStyle}>
          <Box flexGrow={1} sx={isEdit ? { ml: 3 } : null}>
            {isEdit ? t("pages.objectives.editObjective") : t("pages.objectives.newObjective")}
          </Box>
          {isEdit && (
            <IconButton onClick={() => setIsDeleting(true)}>
              <DeleteIcon color={"primary"} />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="description">
            {t("pages.objectives.newObjectiveDescription")}
          </DialogContentText>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <TextField
                error={Boolean(error)}
                fullWidth
                id="department"
                select
                label={t("labels.department")}
                size="small"
                value={selectedObjType || ""}
                onChange={(e) => {
                  setSelectedObjType(e.target.value);
                  setError(undefined);
                }}
                sx={{ mb: 2 }}
                disabled={isEdit}
              >
                {objectiveTypes.objectiveType.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {getObjectiveTypeDescription(item)}
                  </MenuItem>
                ))}
                {/* <MenuItem value={"OT"}>{t("labels.other")}</MenuItem> */}
              </TextField>
            </Grid>
            {selectedObjType !== "OT" && (
              <Grid item xs={12}>
                <TextField
                  error={Boolean(error)}
                  fullWidth
                  id="objective"
                  select
                  label={t("labels.objective")}
                  size="small"
                  value={selectedObjItem || ""}
                  onChange={(e) => {
                    setSelectedObjItem(e.target.value);
                    setError(undefined);
                  }}
                  sx={{ mb: 2 }}
                  disabled={isEdit || selectedObjType === ""}
                >
                  {objectiveTypes.objectiveItem
                    .filter((item) => item.objectivesTypesId === selectedObjType)
                    .map((i, index) => (
                      <MenuItem value={i.id} key={index}>
                        {getObjectiveTypeDescription(i)}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                error={Boolean(error)}
                fullWidth
                id="other-description"
                label={selectedObjType === "OT" ? t("labels.objective") : t("labels.goal")}
                multiline
                rows={3}
                value={description || ""}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setError(undefined);
                }}
                disabled={isEdit}
              />
            </Grid>

            {isEdit && (
              <Grid item xs={12}>
                <FormControlLabel
                  sx={{ ml: 0, mt: 1 }}
                  control={
                    <Switch
                      color="primary"
                      onChange={(e, checked) => {
                        setCompleted(checked);
                      }}
                      checked={completed}
                    />
                  }
                  label={<SecondaryText>{t("labels.completed")}</SecondaryText>}
                  labelPlacement="start"
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </>
    );
  };

  const renderDeleteContent = () => {
    return (
      <>
        <DialogTitle sx={titleStyle} color={"primary"}>
          <Box flexGrow={1}>{"¿Eliminar Objetivo?"}</Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="description">
            {"¿Está seguro que desea elimar el objetivo? Esta acción no se puede deshacer."}
          </DialogContentText>
        </DialogContent>
      </>
    );
  };

  const renderError = () => {
    if (error)
      return (
        <FlexBox ml={3}>
          <ErrorIcon color="error" sx={{ width: 15, height: 15 }} />
          <Typography variant="body2" color="error" ml={0.5}>
            {error}
          </Typography>
        </FlexBox>
      );
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-describedby="description"
      maxWidth={"sm"}
      keepMounted={false}
    >
      <Box p={1}>
        {isDeleting ? renderDeleteContent() : renderContent()}
        {renderError()}
        <DialogActions sx={{ mx: 2 }}>
          <ActionButton
            variant="outlined"
            onClick={handleClose}
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            {t("labels.cancel")}
          </ActionButton>
          <ActionButton variant="contained" onClick={handleSubmit} disabled={diasableSubmit}>
            {t("labels.confirm")}
          </ActionButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ObjectiveFormModal;

import { ObjectivesState } from "./types";

interface State {
  objectives: ObjectivesState;
}

export const objectivesSelector = (state: State) => state.objectives.objectives;
export const objectivesDataSelector = (state: State) => state.objectives.objectives.data || [];
export const isLoadingObjectivesSelector = (state: State) => state.objectives.loadingObjectives;
export const isLoadingCommentSelector = (state: State) => state.objectives.loadingComment;
export const objectiveErrorSelector = (state: State) => state.objectives.objectiveError;
export const commentErrorSelector = (state: State) => state.objectives.commentError;

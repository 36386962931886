import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ICommentItem, IManager, INoteItem, IObjectiveItem } from "../../types";
import { Box, Chip, DialogActions, SxProps, Theme, Typography } from "@mui/material";
import { ActionButton } from "../../styles";
import { useTranslation } from "react-i18next";
import { getObjectiveDescription } from "../../helpers/utils";
import CommentList from "../CommentList";
import { useSelector } from "react-redux";
import { isLoadingCommentSelector } from "../../redux/activities/objectives/selectors";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const objTypeChip: SxProps<Theme> = (theme) => ({
  bgcolor: "secondary.light",
  px: 0.5,
  color: theme.palette.mode === "dark" ? "black" : undefined,
  fontSize: "1.05rem",
});

export interface NoteFormData extends Partial<INoteItem> {
  [key: string]: number | string | boolean | IManager | ICommentItem[] | undefined;
}

const commentContailer: SxProps = {
  maxHeight: "calc(100vh - 320px)",
  width: 700,
  overflow: "auto",
  mt: 1.5,
  pr: 2,
};

interface Props {
  open: boolean;
  objectiveData: IObjectiveItem | null;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ObjectiveModal = ({ open, objectiveData, setOpen }: Props) => {
  const { t } = useTranslation();
  const loadingComments = useSelector(isLoadingCommentSelector);

  const handleClose = (reason?: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") return null;
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={(event, reason) => handleClose(reason)}
      aria-describedby="description"
      maxWidth={"lg"}
    >
      <DialogContent>
        {objectiveData && (
          <Box>
            <Chip
              label={getObjectiveDescription(objectiveData, "type")}
              size="small"
              sx={objTypeChip}
            />
            <Typography variant="h5" sx={{ fontWeight: 400, mt: 0.5 }}>
              {objectiveData?.objectiveTypeId === "OT"
                ? objectiveData.objectiveDescription
                : getObjectiveDescription(objectiveData, "item")}
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "1.1rem", opacity: 0.6, fontWeight: 400 }}>
              {objectiveData?.objectiveTypeId !== "OT" ? objectiveData?.objectiveDescription : null}
            </Typography>
            <Box sx={commentContailer}>
              <CommentList
                item="objective"
                itemId={objectiveData?.id || 0}
                data={objectiveData?.comments || []}
                loading={loadingComments}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <ActionButton
          variant="outlined"
          onClick={() => setOpen(false)}
          sx={{ color: (theme) => theme.palette.text.primary, mx: 2, mb: 1, px: 2 }}
        >
          {t("labels.close")}
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default ObjectiveModal;

import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  styled,
  SxProps,
  Typography,
} from "@mui/material";
import AdminIcon from "@mui/icons-material/Settings";
import { IManager } from "../../types";
import { useTranslation } from "react-i18next";
import { getUserFullName, stringAvatar } from "../../helpers/utils";
import { SecondaryText } from "../../styles";
import UserAvatar from "../../components/UserAvatar";

const StyleTitle = styled(Typography)({
  fontWeight: 600,
});

const StyleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.8,
  },
});

const loaderContent: SxProps = {
  display: "flex",
  justifyContent: "center",
  opacity: 0.8,
  mt: 1,
};

interface Props {
  managers: IManager[];
  selectedUser: number;
  handleManager: (value: string) => void;
  loading?: boolean;
  disabled?: boolean;
  disableManagers?: boolean;
}

const UserSelector = ({
  selectedUser,
  managers,
  loading,
  disabled,
  disableManagers,
  handleManager,
}: Props) => {
  const { t } = useTranslation();
  const noManagers = managers.length === 0 && !loading;

  return (
    <>
      <List dense>
        <ListItem
          alignItems="center"
          secondaryAction={
            <Radio
              checked={selectedUser === 0}
              value={0}
              name={"Admin"}
              inputProps={{ "aria-label": "Admin" }}
              onClick={() => handleManager("0")}
            />
          }
          sx={{ paddingLeft: 0 }}
          disabled={disabled || loading}
        >
          <StyleBox onClick={() => handleManager("0")}>
            <ListItemIcon>
              <AdminIcon sx={{ width: 46, height: 46 }} />
            </ListItemIcon>
            <ListItemText
              primary={<StyleTitle>{"Admin"}</StyleTitle>}
              secondary={t("labels.restaurantSettings")}
            />
          </StyleBox>
        </ListItem>
        <Divider />
        {managers.map(
          (m) =>
            m.active && (
              <ListItem
                alignItems="center"
                secondaryAction={
                  <Radio
                    checked={selectedUser === m.identifier}
                    value={m.identifier}
                    name={m.lastname}
                    inputProps={{ "aria-label": m.name }}
                    onClick={() => handleManager(m.identifier.toString())}
                  />
                }
                sx={{ paddingLeft: 0 }}
                key={m.identifier}
                disabled={disabled || disableManagers || loading}
              >
                <StyleBox onClick={() => handleManager(`${m.identifier}`)}>
                  <ListItemIcon>
                    <UserAvatar>{stringAvatar(m)}</UserAvatar>
                  </ListItemIcon>
                  <ListItemText
                    primary={<StyleTitle>{getUserFullName(m)}</StyleTitle>}
                    secondary={m.identifier}
                  />
                </StyleBox>
              </ListItem>
            )
        )}
      </List>
      {noManagers && <SecondaryText mt={2}>{t("labels.noActiveLeaders")}</SecondaryText>}
      {loading && (
        <Box sx={loaderContent}>
          <CircularProgress size={40} />
        </Box>
      )}
    </>
  );
};

export default UserSelector;

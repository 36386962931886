import { call, put, select, takeEvery } from "redux-saga/effects";
import { PromiseResult } from "../../../helpers/utils";
import ActivityServices from "../../../services/ActivityServices";
import { AlertCode, IHttpRequestError, IManager, IQueryParams, ISyncState } from "../../../types";
import { setSnackbar, setSyncFailed, setSyncRequired } from "../../app/actions";
import { updateHourlyReviewDone, updateHourlyReviewError } from "./actions";
import { ActionType, UpdateHourlyReviewRequestedAction } from "./types";
import AppStorage from "../../../services/AppStorage";
import { appUserSelector } from "../../user/selectors";
import { isAppOnlineSelector, syncRequiredSelector } from "../../app/selectors";
import { ApiResponse } from "../../../services/interfaces";

function* updateHourlyReviewSagas(action: UpdateHourlyReviewRequestedAction) {
  const isAppOnline: boolean = yield select(isAppOnlineSelector);
  const syncRequired: ISyncState = yield select(syncRequiredSelector);
  const user: IManager = yield select(appUserSelector);
  const params: IQueryParams = {
    username: user.username,
  };
  const data = action.payload.hourlyReviews;
  try {
    if (isAppOnline) {
      const {
        result: updateHourlyReviewResponse,
        hasError,
        error,
      }: PromiseResult<ApiResponse> = yield call(ActivityServices.updateHourlyReview, data, params);
      if (hasError || !updateHourlyReviewResponse) throw error;
      AppStorage.clearHourlyReviews();
    } else {
      yield put(setSyncRequired(true));
      AppStorage.setHourlyReviews(data);
    }
    yield put(updateHourlyReviewDone({ hourlyReviews: data }));
  } catch (error) {
    yield put(updateHourlyReviewError({ hourlyReviews: data }));
    yield put(
      setSnackbar({
        alert: {
          code: AlertCode.HOURLY_REVIEW_ERROR,
          error: error as IHttpRequestError,
          severity: "error",
        },
      })
    );
    if (syncRequired) yield put(setSyncFailed(true));
  }
}

const activitySagas = [takeEvery(ActionType.updateHourlyReviewRequested, updateHourlyReviewSagas)];

export default activitySagas;

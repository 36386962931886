import React from "react";
import {
  ToggleButtonGroup,
  ToggleButton,
  styled,
  Button,
  Box,
  SxProps,
  Theme,
} from "@mui/material";
import { grey } from "@mui/material/colors";


const enabledStyle: SxProps<Theme> = (theme) => ({
  paddingInline: theme.spacing(2),
  borderRadius: 20,
  borderWidth: 2.5,
  color: theme.palette.primary.main,
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "#FFF",
    backgroundColor: theme.palette.primary.main,
  },
});

const disabledStyle: SxProps<Theme> = (theme) => ({
  paddingInline: theme.spacing(2),
  borderRadius: 20,
  borderWidth: 2.5,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.mode === "light" ? grey[200] : grey[700],
  "&.Mui-selected, &.Mui-selected:hover": {
    color: theme.palette.mode === "light" ? "#FFF" : grey[500],
    backgroundColor: theme.palette.mode === "light" ? grey[500] : grey[800],
  },
});


interface Props {
  values: number[];
  selected: number[];
  time: string;
  disabled: boolean;
  onSelect: (value: number[], time: string) => void;
}

const RushHoursSelector = ({ values, selected, time, disabled, onSelect }: Props) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, newFormats: number[]) => {
    onSelect(newFormats, time);
  };

  return (
    <ToggleButtonGroup
      fullWidth
      size="small"
      value={selected}
      onChange={handleChange}
      disabled={disabled}
    >
      {values.map((hour) => (
        <ToggleButton key={hour} value={hour} sx={disabled ? disabledStyle : enabledStyle}>
          {hour === 0 ? `0${hour}:00` : `${hour}:00`}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default RushHoursSelector;

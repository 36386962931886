import { areasChecklistFormatter } from "../../../helpers/utils";
import { ActivityType, IAreaChecklist, IActivityItem, IStorePlan } from "../../../types";
import { doneRemoteResource, initialRemoteResource } from "../../utils";
import { Actions, ActionType, ChecklistState } from "./types";

const initialState: ChecklistState = {
  preShift: initialRemoteResource([]),
  posShift: initialRemoteResource([]),
  opening: initialRemoteResource({}),
  closing: initialRemoteResource({}),
  checklist: initialRemoteResource({}),
  myActivities: initialRemoteResource([]),
  loadingPreshift: false,
  loadingPosShift: false,
  loadingOpening: false,
  loadingClosing: false,
  loadingChecklist: false,
  loadingMyActivities: false,
  preShiftSyncError: false,
  posShiftSyncError: false,
  openingSyncError: false,
  closingSyncError: false,
  checklistSyncError: false,
  myActivitiesSyncError: false,
};

const handleAreaChecklist = (data: IActivityItem[]) => {
  const formattedData: IAreaChecklist = areasChecklistFormatter(data);
  return formattedData;
};

const handleSetChecklistsData = (state: ChecklistState, data: IStorePlan): ChecklistState => {
  return {
    ...state,
    opening: doneRemoteResource(handleAreaChecklist(data.OPN)),
    closing: doneRemoteResource(handleAreaChecklist(data.CLO)),
    checklist: doneRemoteResource(handleAreaChecklist(data.CHK)),
    preShift: doneRemoteResource(data.PRE),
    posShift: doneRemoteResource(data.POS),
    myActivities: doneRemoteResource(data.CUS),
    preShiftSyncError: false,
    posShiftSyncError: false,
    openingSyncError: false,
    closingSyncError: false,
    checklistSyncError: false,
    myActivitiesSyncError: false,
  };
};

const handleActivity = (
  state: ChecklistState,
  data: IAreaChecklist,
  activityTypesId: string
): ChecklistState => {
  switch (activityTypesId) {
    case ActivityType.OPENING:
      return {
        ...state,
        opening: doneRemoteResource(data),
        loadingOpening: false,
        openingSyncError: false,
      };
    case ActivityType.CLOSING:
      return {
        ...state,
        closing: doneRemoteResource(data),
        loadingClosing: false,
        closingSyncError: false,
      };
    case ActivityType.CHECKLIST:
      return {
        ...state,
        checklist: doneRemoteResource(data),
        loadingChecklist: false,
        checklistSyncError: false,
      };
    default:
      return { ...state };
  }
};

const handleActivityLoading = (
  state: ChecklistState,
  activityTypesId: string,
  loading: boolean,
  error?: boolean
): ChecklistState => {
  switch (activityTypesId) {
    case ActivityType.OPENING:
      return {
        ...state,
        loadingOpening: loading,
        openingSyncError: error || state.openingSyncError,
      };
    case ActivityType.CLOSING:
      return {
        ...state,
        loadingClosing: loading,
        closingSyncError: error || state.closingSyncError,
      };
    case ActivityType.CHECKLIST:
      return {
        ...state,
        loadingChecklist: loading,
        checklistSyncError: error || state.checklistSyncError,
      };
    default:
      return { ...state };
  }
};

/** REDUCER */

const activityReducer = (state: ChecklistState = initialState, action: Actions): ChecklistState => {
  switch (action.type) {
    case ActionType.setChecklistsData:
      return {
        ...handleSetChecklistsData(state, action.payload.data),
      };
    /** PRESHIFT */
    case ActionType.setPreShiftRequested:
      return {
        ...state,
        loadingPreshift: true,
      };
    case ActionType.setPreShiftDone:
      return {
        ...state,
        preShift: doneRemoteResource(action.payload.data as IActivityItem[]),
        preShiftSyncError: false,
        loadingPreshift: false,
      };
    case ActionType.setPreShiftError:
      return {
        ...state,
        preShiftSyncError: true,
        loadingPreshift: false,
      };
    /** POSTSHIFT */
    case ActionType.setPosShiftRequested:
      return {
        ...state,
        loadingPosShift: true,
      };
    case ActionType.setPosShiftDone:
      return {
        ...state,
        posShift: doneRemoteResource(action.payload.data as IActivityItem[]),
        posShiftSyncError: false,
        loadingPosShift: false,
      };
    case ActionType.setPosShiftError:
      return {
        ...state,
        posShiftSyncError: true,
        loadingPosShift: false,
      };
    /** AREA CHECKLIST */
    case ActionType.setAreasChecklistRequested:
      return {
        ...handleActivityLoading(state, action.payload.activityTypesId, true),
      };
    case ActionType.setAreasChecklistDone:
      return {
        ...handleActivity(
          state,
          action.payload.data as IAreaChecklist,
          action.payload.activityTypesId
        ),
      };
    case ActionType.setAreasChecklistError:
      return {
        ...handleActivityLoading(state, action.payload.activityTypesId, false, true),
      };
    /** MY ACTIVITIES */
    case ActionType.setMyActivitiesRequested:
      return {
        ...state,
        loadingMyActivities: true,
      };
    case ActionType.setMyActivitiesDone:
      return {
        ...state,
        myActivities: doneRemoteResource(action.payload.data as IActivityItem[]),
        myActivitiesSyncError: false,
        loadingMyActivities: false,
      };
    case ActionType.setMyActivitiesError:
      return {
        ...state,
        myActivitiesSyncError: true,
        loadingMyActivities: false,
      };

    default:
      return state;
  }
};

export default activityReducer;

import React from "react";
import { Box, SxProps, Typography } from "@mui/material";
import { BoldTypography, FlexBox, LightTypography } from "../../styles";
import UpIcon from "@mui/icons-material/North";
import DownIcon from "@mui/icons-material/South";
import GCIcon from "@mui/icons-material/Discount";
import IPIcon from "@mui/icons-material/Engineering";
import StoreIcon from "@mui/icons-material/Storefront";
import { ISeverity } from "../DailyTotalsDashboard";
import { numberFormatter } from "../../helpers/utils";

const containerBox: SxProps = {
  justifyContent: "center",
  p: 1,
};

const valueStyle: SxProps = {
  lineHeight: 1,
  mb: 0.7,
  fontSize: "2.4rem",
};

const textStyle: SxProps = {
  lineHeight: 1,
  mb: 0.7,
  fontSize: "2.5rem",
};

const severityIcon: SxProps = {
  height: 14,
  width: 14,
  mb: 1,
};

const gcIcon: SxProps = {
  height: 36,
  width: 36,
  color: "primary.main",
  mb: 0.5,
  mx: 0.37,
};

const ipIcon: SxProps = {
  height: 42,
  width: 42,
  color: "primary.main",
  mb: 0.5,
};

type IconType = "gc" | "ip" | "store";

interface Props {
  severity?: ISeverity;
  title: string;
  value?: number | string;
  type?: IconType;
}

function getIcon(type?: IconType) {
  switch (type) {
    case "gc":
      return <GCIcon sx={gcIcon} />;
    case "ip":
      return <IPIcon sx={ipIcon} />;
    case "store":
      return <StoreIcon sx={ipIcon} />;

    default:
      return null;
  }
}

function getSeverity(formattedValue: string, severity?: ISeverity) {
  if (!severity) return null;

  return (
    <FlexBox
      sx={{
        alignSelf: "flex-start",
        color: severity?.negative ? "red" : "success.main",
        mt: 0.25,
      }}
    >
      {severity.negative ? (
        <DownIcon sx={{ ...severityIcon, mb: 0 }} />
      ) : (
        <UpIcon sx={{ ...severityIcon, mb: 0.25 }} />
      )}
      <Typography variant="body2">{`${formattedValue}%`}</Typography>
    </FlexBox>
  );
}

const SegmentTotalStatCard = ({ title, severity, value, type }: Props) => {
  const formattedValue = numberFormatter(severity?.value || 0, 1, 2);
  return (
    <FlexBox mx={2} my={1}>
      {getIcon(type)}
      <Box sx={containerBox}>
        <LightTypography variant="body2" sx={{ ml: 0.4 }}>
          {title}
        </LightTypography>
        <FlexBox>
          <BoldTypography variant="h4" sx={type === "store" ? textStyle : valueStyle}>
            {value || 0}
          </BoldTypography>
          {getSeverity(formattedValue, severity)}
        </FlexBox>
      </Box>
    </FlexBox>
  );
};

export default SegmentTotalStatCard;

import { Box, CssBaseline, styled } from "@mui/material";
import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { isAppOnlineSelector } from "../redux/app/selectors";
import NoteFormModal from "../components/modals/NoteFormModal";
import { setAlert, setIsAppOnline } from "../redux/app/actions";
import NavigateController from "../components/NavigateController";
import SalesRequestTimer from "../components/SalesRequestTimer";
import { useTranslation } from "react-i18next";
import AppSnackbar from "../components/AppSnackbar";
import AlertModal from "../components/modals/AlertModal";

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  padding: theme.spacing(3),
  marginTop: theme.spacing(7),
  backgroundColor:
    theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
}));

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAppOnline = useSelector(isAppOnlineSelector);

  window.addEventListener("offline", (e) => {
    e.stopImmediatePropagation();
    dispatch(setIsAppOnline({ value: false }));
  });

  window.addEventListener("online", (e) => {
    e.stopImmediatePropagation();
    dispatch(setIsAppOnline({ value: true }));
  });

  document.addEventListener("new-version", () => {
    const title = t("messages.newVersion.title").toString();
    const message = t("messages.newVersion.content").toString();
    const actionLabel = t("labels.reloadPage").toString();
    dispatch(setAlert({ alert: { title, message, action: "new-version", actionLabel } }));
  });

  // let d_phone = useMediaQuery("(max-width:480px)");
  // let d_tablet = useMediaQuery("(max-width:768px)");
  // let d_desktop = useMediaQuery("(max-width:992px)");
  // let orientation = window.matchMedia("(orientation: portrait)");

  // console.log("MediaQuery", {
  //   navigator: navigator.userAgent,
  //   devices: { phone: d_phone, tablet: d_tablet, desktop: d_desktop },
  //   orientation: orientation.matches ? "portrait" : "landscape",
  // });

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      <Header isAppOnline={isAppOnline} />
      <Sidebar isAppOnline={isAppOnline} />
      <Main>{children}</Main>
      <NoteFormModal />
      <NavigateController />
      <SalesRequestTimer />
      <AppSnackbar />
      <AlertModal />
    </Box>
  );
};

export default Layout;

import React from "react";
import {
  CustomContentProps,
  SnackbarContent,
  closeSnackbar,
  useSnackbar,
} from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  isAppOnlineSelector,
  snackbarSelector,
  syncFailedSelector,
  syncRequiredSelector,
} from "../redux/app/selectors";
import { IAlertMessage, IErrorResponse } from "../types";
import { consumeSnackbar } from "../redux/app/actions";
import { SxProps, Box, Paper, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Cancel";
import { secondary_light } from "../theme/theme";
import { useNavigate } from "react-router-dom";
import { routes } from "../router/routes";
import { isValidErrorMessage } from "../helpers/utils";

const containerBox: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 1.5,
  backgroundColor: secondary_light,
};

const smIcon: SxProps = {
  width: 18,
  height: 18,
  mr: 1,
};

export const ConnectionSB = React.forwardRef<
  HTMLDivElement,
  CustomContentProps
>((props, ref) => {
  const { message } = props;

  return (
    <SnackbarContent ref={ref} role="alert">
      <Box component={Paper} sx={containerBox}>
        <ErrorIcon sx={smIcon} color="error" />
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {message}
        </Typography>
      </Box>
    </SnackbarContent>
  );
});

const AppSnackbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const snackbar = useSelector(snackbarSelector);
  const isAppOnline = useSelector(isAppOnlineSelector);
  const syncRequired = useSelector(syncRequiredSelector);
  const syncFailed = useSelector(syncFailedSelector);

  const handleNotification = (alert: IAlertMessage) => {
    const code = alert.error?.innerException?.code;

    if (code === "ERR_NETWORK" && !syncRequired && !syncFailed && isAppOnline) {
      navigate(routes.maintenance.path);
      return null;
    }

    const error = alert.error?.data as IErrorResponse;
    const variant = alert.severity;
    let message = error?.message
      ? t(`errors.${error.message}`)
      : t(`notifications.${alert.code}`);

    if (!isValidErrorMessage(message)) message = t("errors.codes.-1.title");

    enqueueSnackbar(message, {
      variant,
      preventDuplicate: true,
    });
    dispatch(consumeSnackbar());
  };

  const handleConnectionSbar = () => {
    enqueueSnackbar(t("messages.noConnection"), {
      variant: "connectionAlert",
      persist: true,
      TransitionProps: { direction: "up" },
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      preventDuplicate: true,
    });
  };

  React.useEffect(() => {
    snackbar && handleNotification(snackbar);
    // eslint-disable-next-line
  }, [snackbar]);

  React.useEffect(() => {
    if (isAppOnline) {
      closeSnackbar();
    } else {
      closeSnackbar();
      handleConnectionSbar();
    }
    // eslint-disable-next-line
  }, [isAppOnline]);

  return null;
};

export default AppSnackbar;

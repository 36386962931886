import { Action } from "@reduxjs/toolkit";
import { RemoteResource } from "../../utils";
import { ICommentItem, IObjectiveItem } from "../../../types";
import { ActionPayload } from "../../actions";

export interface ObjectivesState {
  objectives: RemoteResource<IObjectiveItem[]>;
  objectiveError: boolean;
  commentError: boolean;
  loadingObjectives: boolean;
  loadingComment: boolean;
}

export enum ActionType {
  getObjectivesRequested = "objectives/get-objectives-requested",
  getObjectivesDone = "objectives/get-objectives-done",
  getObjectivesError = "objectives/get-objectives-error",
  addObjectiveRequested = "objectives/add-objective-requested",
  addObjectiveDone = "objectives/add-objective-done",
  addObjectiveError = "objectives/add-objective-error",
  updateObjectiveRequested = "objectives/update-objective-requested",
  updateObjectiveDone = "objectives/update-objective-done",
  updateObjectiveError = "objectives/update-objective-error",
  deleteObjectiveRequested = "objectives/delete-objective-requested",
  deleteObjectiveDone = "objectives/delete-objective-done",
  deleteObjectiveError = "objectives/delete-objective-error",
  addCommentRequested = "objectives/add-comment-requested",
  addCommentDone = "objectives/add-comment-done",
  addCommentError = "objectives/add-comment-error",
  updateCommentRequested = "objectives/update-comment-requested",
  updateCommentDone = "objectives/update-comment-done",
  updateCommentError = "objectives/update-comment-error",
  deleteCommentRequested = "objectives/delete-comment-requested",
  deleteCommentDone = "objectives/delete-comment-done",
  deleteCommentError = "objectives/delete-comment-error",
  clearObjectives = "objectives/clear-objectives",
}

/** GET OBJECTIVES */

export interface GetObjectivesPayload {
  objective: IObjectiveItem[];
}

export interface GetObjectivesRequestedAction extends Action<ActionType.getObjectivesRequested> {}

export interface GetObjectivesDoneAction
  extends ActionPayload<ActionType.getObjectivesDone, GetObjectivesPayload> {}

export interface GetObjectivesErrorAction extends Action<ActionType.getObjectivesError> {}

/** ADD OBJECTIVES */

export interface AddObjectivePayload {
  objective: Partial<IObjectiveItem>;
}
export interface AddObjectiveRequestedAction
  extends ActionPayload<ActionType.addObjectiveRequested, AddObjectivePayload> {}

export interface AddObjectiveDoneAction
  extends ActionPayload<ActionType.addObjectiveDone, AddObjectivePayload> {}

export interface AddObjectiveErrorAction extends Action<ActionType.addObjectiveError> {}

/** UPDATE OBJECTIVES */

export interface UpdateObjectivePayload {
  objective: Partial<IObjectiveItem>;
}
export interface UpdateObjectiveRequestedAction
  extends ActionPayload<ActionType.updateObjectiveRequested, UpdateObjectivePayload> {}

export interface UpdateObjectiveDoneAction
  extends ActionPayload<ActionType.updateObjectiveDone, UpdateObjectivePayload> {}

export interface UpdateObjectiveErrorAction extends Action<ActionType.updateObjectiveError> {}

/** DELETE OBJECTIVES */

export interface DeleteObjectivePayload {
  id: number;
}
export interface DeleteObjectiveRequestedAction
  extends ActionPayload<ActionType.deleteObjectiveRequested, DeleteObjectivePayload> {}

export interface DeleteObjectiveDoneAction
  extends ActionPayload<ActionType.deleteObjectiveDone, DeleteObjectivePayload> {}

export interface DeleteObjectiveErrorAction extends Action<ActionType.deleteObjectiveError> {}

/** ADD COMMENT */

export interface AddCommentPayload {
  comment: Partial<ICommentItem>;
}
export interface AddCommentRequestedAction
  extends ActionPayload<ActionType.addCommentRequested, AddCommentPayload> {}

export interface AddCommentDoneAction
  extends ActionPayload<ActionType.addCommentDone, AddCommentPayload> {}

export interface AddCommentErrorAction extends Action<ActionType.addCommentError> {}

/** UPDATE COMMENT */

export interface UpdateCommentPayload {
  comment: Partial<ICommentItem>;
}
export interface UpdateCommentRequestedAction
  extends ActionPayload<ActionType.updateCommentRequested, UpdateCommentPayload> {}

export interface UpdateCommentDoneAction
  extends ActionPayload<ActionType.updateCommentDone, UpdateCommentPayload> {}

export interface UpdateCommentErrorAction extends Action<ActionType.updateCommentError> {}

/** DELETE COMMENT */

export interface DeleteCommentPayload {
  comment: Partial<ICommentItem>;
}
export interface DeleteCommentRequestedAction
  extends ActionPayload<ActionType.deleteCommentRequested, DeleteCommentPayload> {}

export interface DeleteCommentDoneAction
  extends ActionPayload<ActionType.deleteCommentDone, DeleteCommentPayload> {}

export interface DeleteCommentErrorAction extends Action<ActionType.deleteCommentError> {}

/** CLEAR */

export interface ClearObjectivesAction extends Action<ActionType.clearObjectives> {}

/** ACTIONS TYPES */

export type Actions =
  | GetObjectivesRequestedAction
  | GetObjectivesDoneAction
  | GetObjectivesErrorAction
  | AddObjectiveRequestedAction
  | AddObjectiveDoneAction
  | AddObjectiveErrorAction
  | UpdateObjectiveRequestedAction
  | UpdateObjectiveDoneAction
  | UpdateObjectiveErrorAction
  | DeleteObjectiveRequestedAction
  | DeleteObjectiveDoneAction
  | DeleteObjectiveErrorAction
  | AddCommentRequestedAction
  | AddCommentDoneAction
  | AddCommentErrorAction
  | UpdateCommentRequestedAction
  | UpdateCommentDoneAction
  | UpdateCommentErrorAction
  | DeleteCommentRequestedAction
  | DeleteCommentDoneAction
  | DeleteCommentErrorAction
  | ClearObjectivesAction;

import React, { useEffect } from "react";
import { Collapse, Paper, SxProps, Theme, Typography, styled } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { DateCalendar } from "@mui/x-date-pickers";
import ShiftSelector from "../user-manager/ShiftSelector";
import { Box } from "@mui/system";
import { IManager, IScheduleItem, AppPermissions, ActivityType, AlertCode } from "../../types";
import { addDays, subDays } from "date-fns";
import UserSelector from "../user-manager/UserSelector";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAppUser } from "../../redux/user/actions";
import { filtersSelector } from "../../redux/admin/masterdata/selectors";
import { areasDataSelector } from "../../redux/admin/areas/selectors";
import { managersSelector } from "../../redux/admin/managers/selectors";
import { schedulesDataSelector } from "../../redux/admin/schedules/selectors";
import { appUserSelector, permissionsDataSelector } from "../../redux/user/selectors";
import FiltersToolbar from "../../components/FiltersToolbar";
import { ActionButton, FlexBox, SecondaryText } from "../../styles";
import { setFilters } from "../../redux/admin/masterdata/actions";
import {
  getHourlyReviewTypesRequested,
  getStorePlanRequested,
  setStorePlan,
} from "../../redux/storePlan/actions";
import AdminCard from "../../components/cards/AdminCard";
import {
  loadingObjectivesSelector,
  loadingStorePlanSelector,
} from "../../redux/storePlan/selectors";
import LoginIcon from "@mui/icons-material/Login";
import { RemoteResourceState } from "../../redux/utils";
import StoreDetailsCard from "../../components/cards/StoreDetailsCard";
import {
  getManagersCompleted,
  getAreasCompleted,
  getSchedulesCompleted,
  getISOString,
} from "../../helpers/utils";
import { setSnackbar, setSyncFailed, setSyncRequired } from "../../redux/app/actions";
import AppStorage from "../../services/AppStorage";
import { isAppOnlineSelector } from "../../redux/app/selectors";
import { isLoadingActivitySelector } from "../../redux/activities/checklists/selectors";
import { clearObjectives } from "../../redux/activities/objectives/actions";
import { clearNotes } from "../../redux/activities/notes/actions";
import { loadingNoteSelector } from "../../redux/activities/notes/selectors";

const boxStyle: SxProps = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const contentHeader: SxProps<Theme> = (theme) => ({
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    display: "block",
  },
});

const UserContainer = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 245px)",
  maxHeight: 500,
  px: 1,
  overflow: "auto",
  [theme.breakpoints.down("lg")]: {
    height: 300,
  },
}));

const StoreInfoContainer = styled(Box)(({ theme }) => ({
  mb: 3,
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

function getSelectedDate(timestamp?: string) {
  if (timestamp) return new Date(timestamp);
  return null;
}

const ManagerLogin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAppOnline = useSelector(isAppOnlineSelector);
  const user = useSelector(appUserSelector);
  const permissions = useSelector(permissionsDataSelector);
  const schedules = useSelector(schedulesDataSelector);
  const areas = useSelector(areasDataSelector);
  const filters = useSelector(filtersSelector);
  const loadingStorePlan = useSelector(loadingStorePlanSelector);
  const loadingChecklist = useSelector(isLoadingActivitySelector);
  const loadingObj = useSelector(loadingObjectivesSelector);
  const loadingNotes = useSelector(loadingNoteSelector);
  const loading = loadingStorePlan || loadingChecklist || loadingObj || loadingNotes;
  const { state: managersState, data: managers } = useSelector(managersSelector);
  const managersLoading = managersState === RemoteResourceState.REMOTE_RESOURCE_LOADING;
  const [selectedManager, setSelectedManager] = React.useState<number>(user?.identifier || 0);
  const [selectedDate, setSelectedDate] = React.useState(getSelectedDate(filters?.businessDate));
  const [selectedShift, setSelectedShift] = React.useState(filters?.shiftTypeId);
  const managersCompleted = getManagersCompleted(managers);
  const schedulesCompleted = getSchedulesCompleted(schedules);
  const areasCompleted = getAreasCompleted(areas);
  const isConfigCompleted = areasCompleted && schedulesCompleted && managersCompleted;
  const isSelectionCompleted = isConfigCompleted && selectedManager > 0 && selectedDate;
  const { country, store } = filters;

  useEffect(() => {
    if (managers.length === 0) setSelectedManager(0);
  }, [managers]);

  useEffect(() => {
    if (!filters.businessDate) setSelectedDate(null);
  }, [filters.businessDate]);

  /** HANDLERS */

  const handleSubmit = () => {
    if (selectedManager === 0) {
      const admin: IManager = {
        identifier: 0,
        name: "Admin",
        lastname: "",
        active: true,
        rol: "admin",
      };
      dispatch(setAppUser({ appUser: { ...admin } }));
      dispatch(setStorePlan({ storePlan: null }));
    } else {
      const user = managers.find((m) => m.identifier === selectedManager && m.identifier !== 0);
      let activities = [
        ActivityType.PRE_SHIFT,
        ActivityType.POS_SHIFT,
        ActivityType.CHECKLIST,
        ActivityType.OPENING,
        ActivityType.CLOSING,
        ActivityType.SHIFT_TOUR,
      ];
      if (user && selectedShift && country && store) {
        AppStorage.clearActivities();
        dispatch(clearObjectives());
        dispatch(clearNotes());
        dispatch(setSyncRequired(false));
        dispatch(setSyncFailed(false));
        dispatch(setSnackbar({ alert: { code: AlertCode.LOADING_STORE_PLAN } }));
        dispatch(setAppUser({ appUser: { ...user, rol: "manager" } }));
        dispatch(getStorePlanRequested({ activityTypesId: activities.join() }));
        dispatch(getHourlyReviewTypesRequested());
      }
    }
  };

  const handleManager = (value: string) => {
    if (isConfigCompleted) {
      setSelectedManager(parseInt(value));
      setSelectedShift(undefined);
    }
  };

  const handleDate = (value: Date | null) => {
    if (value) {
      setSelectedDate(value);
      dispatch(
        setFilters({
          filters: {
            ...filters,
            businessDate: getISOString(value),
            shiftTypeId: undefined,
          },
        })
      );
      setSelectedShift(undefined);
    }
  };

  const handleShift = (item: IScheduleItem) => {
    setSelectedShift(item.shiftTypeId);
    dispatch(
      setFilters({ filters: { ...filters, shiftTypeId: item.shiftTypeId, shift: { ...item } } })
    );
  };

  /** RENDERS */

  const renderHeader = () => {
    return (
      <FlexBox sx={contentHeader}>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Typography variant="h5" mr={1.5}>
              {t("pages.home.welcome.title")}
            </Typography>
            <span style={{ transform: "scale(1.5)" }}>👋</span>
          </Box>

          <SecondaryText variant="body2" sx={{ fontStyle: "italic" }}>{`${t(
            "pages.home.welcome.subtitle"
          )} - Alexander Graham Bell`}</SecondaryText>
        </Box>
        {permissions.includes(AppPermissions.SUPPORT) && <FiltersToolbar />}
      </FlexBox>
    );
  };

  const renderUserSelection = () => {
    return (
      <Box component={Paper} px={2} py={1}>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {t("pages.home.manager.title")}
        </Typography>
        <UserContainer>
          <UserSelector
            managers={managers}
            selectedUser={selectedManager}
            handleManager={handleManager}
            loading={managersLoading}
            disabled={loading}
            disableManagers={!isConfigCompleted}
          />
        </UserContainer>
      </Box>
    );
  };

  const storeProgress = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box component={Paper}>
            <AdminCard
              title={t("labels.settings")}
              progress={{
                managers: managersCompleted,
                schedules: schedulesCompleted,
                areas: areasCompleted,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} textAlign={"center"}>
          <ActionButton
            variant="contained"
            onClick={handleSubmit}
            startIcon={<LoginIcon />}
            sx={{ width: "30%", minWidth: 120 }}
            loading={loading}
            disabled={loading || !isAppOnline}
          >
            {t("labels.setupRestaurant")}
          </ActionButton>
        </Grid>
      </Grid>
    );
  };

  const storePlanSelection = () => {
    return (
      <Grid container spacing={3}>
        <Grid item md={5} xs={12}>
          <Box component={Paper} p={1} sx={boxStyle}>
            <Typography variant="h6">{t("pages.home.calendar.title")}</Typography>
            <DateCalendar
              minDate={subDays(new Date(), 1)}
              maxDate={addDays(new Date(), 3)}
              value={selectedDate}
              onChange={handleDate}
              sx={{ m: 0, maxHeight: 322 }}
              disabled={loading || !isAppOnline}
            />
          </Box>
        </Grid>
        <Grid item md={7} xs={12}>
          <Box component={Paper} p={1} sx={{ height: "100%" }}>
            <Typography variant="h6" textAlign={"center"}>
              {t("pages.home.shifts.title")}
            </Typography>
            <ShiftSelector
              onChange={handleShift}
              onSubmit={handleSubmit}
              selectedDate={selectedDate}
              schedules={schedules}
              selectedShift={selectedShift}
              disabled={!isAppOnline || !isSelectionCompleted}
              loading={loading}
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {renderHeader()}
        </Grid>
        <Grid item lg={4} xs={12}>
          {renderUserSelection()}
        </Grid>
        <Grid item lg={8} xs={12}>
          <StoreInfoContainer component={Paper}>
            <StoreDetailsCard filters={filters} />
          </StoreInfoContainer>
          <Collapse in={selectedManager === 0}>{storeProgress()}</Collapse>
          <Collapse in={selectedManager !== 0}>{storePlanSelection()}</Collapse>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ManagerLogin;
